import React, { useState } from "react"
import WelcomeMessage from "welcome-message.svg"
import { CanopyButton } from "@parachutehealth/canopy-button"
import AutomatedWelcomeMessageForm from "../AutomatedWelcomeMessageForm"
import * as patientPortal from "services/patientPortal"
import { SupplierSettings } from "services/patientPortal"
import withInitialData from "components/withInitialData"
import { styleTemplateVariables } from "../helpers"

interface Props {
  initialData: SupplierSettings
  supplierId: string
}

const AutomatedWelcomeMessageHero = ({
  showForm,
  description,
}: {
  showForm: () => void
  description: string
}) => {
  return (
    <div
      style={{ maxWidth: "60%", textAlign: "center" }}
      className="canopy-mx-auto"
    >
      <img
        src={WelcomeMessage}
        alt="Hands with text bubble saying welcome"
        className="canopy-m-auto"
        style={{ width: "144px", height: "144px" }}
      />
      <h1 className="canopy-m-auto">Send patients a welcome message</h1>

      <p className="canopy-mt-4x">{description}</p>
      <CanopyButton onClick={showForm}>Add Welcome Message</CanopyButton>
    </div>
  )
}

const AutomatedWelcomeMessage = ({ initialData, supplierId }: Props) => {
  const [showForm, setShowForm] = useState(false)
  const [supplierSettings, setSupplierSettings] = useState(initialData)

  const initialAction = supplierSettings.automatedWelcomeMessage
    ? "Edit"
    : "Add"

  const [action, setAction] = useState(initialAction)

  const description =
    "Send personalized, automated welcome messages to patients and set clear expectations. You could include (a) the steps in your process, (b) how they might reach out to you, or (c) what to do if they have questions."

  const endpoint = patientPortal.build()

  const deleteWelcomeMessage = () =>
    endpoint
      .updateSupplier(supplierId, "")
      .then(({ data }) => data)
      .catch(({ response }) => Promise.reject(response.data))
      .then((data) => setSupplierSettings(data))

  if (showForm) {
    return (
      <AutomatedWelcomeMessageForm
        action={action}
        supplierId={supplierId}
        supplierSettings={supplierSettings}
        hideForm={() => setShowForm(false)}
        setSupplierSettings={setSupplierSettings}
      />
    )
  }

  if (!showForm && supplierSettings.automatedWelcomeMessage) {
    return (
      <div>
        <div className="d-flex">
          <div>
            <p>{description}</p>
          </div>
        </div>

        <table style={{ tableLayout: "fixed" }} className="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Name</th>
              <th style={{ width: "68%" }}>Message</th>
              <th style={{ width: "12%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b>Welcome Message</b>
              </td>
              <td className="text-truncate">
                {styleTemplateVariables(
                  supplierSettings.automatedWelcomeMessage
                )}
              </td>
              <td>
                <button
                  onClick={() => {
                    setAction("Edit")
                    setShowForm(true)
                  }}
                  className="link"
                >
                  Edit
                </button>
                |
                <button onClick={deleteWelcomeMessage} className="link">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <AutomatedWelcomeMessageHero
      showForm={() => setShowForm(true)}
      description={description}
    />
  )
}

const fetchInitialData = ({
  supplierId,
}: Omit<Props, "initialData">): Promise<SupplierSettings> => {
  const endpoint = patientPortal.build()

  return endpoint.fetchSupplier(supplierId).then(({ data }) => data)
}

export default withInitialData(fetchInitialData)(AutomatedWelcomeMessage)
