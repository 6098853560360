import "core-js/stable"
import "regenerator-runtime/runtime"
import DocumentUploader from "../components/document_uploader"
import DocumentViewer from "../components/document_viewer"
import { SidebarTab } from "applications/Workflow/containers/Navigation/sharedTypes"
import { DmeOrderCreatedVia } from "../applications/Workflow/containers/Review/components/SupplierSendBack"
import { GridFilterModel } from "@mui/x-data-grid-pro"
import { ServerSideDataGridSort } from "../applications/Cms/hooks/useServerSideDataGrid"

export type { History } from "history"

export type Nullable<T> = T | null

// Most Window fields are defined in app-workflow/app/helpers/react_helper.rb
// We add them here to make them available to typescript
declare global {
  interface Document {
    webkitHidden: string
    msHidden: string
    activeElement: HTMLInputElement
  }

  interface Window {
    _detector?: GlassboxDetector
    adobeApiKey: string
    glassboxJsUrl: string
    prohibitedUserMonitoringPaths: string[]
    handleRemoteFormChange?: () => void
    clientsideInstrumentation: ClientsideInstrumentationWindow
    datadogConfiguration: DatadogConfigurationWindow
    EVENTS?
    clipboardData: {
      getData: (string: string) => string
    }
    Upscope?: (operation: string, options) => void
    upscopeUrl: string
    upscopeApiKey: string
    _paq?: string[]
    matomo: {
      serverUrl: string
      siteId: string
      analyticsPageTitle: string
      externalVisitIdCustomDimensionId?: string
      externalVisitId?: string
    }
    parachute?: ParachuteWindow
    patientPortalJwtSlug: string
    patientPortalUrl: string
    patientPortalWsUrl: string
    sentryConfig: {
      dsn: string
      release: string
      releaseStage: string
      replaysSessionSampleRate: number
      replaysOnErrorSampleRate: number
      domainSuffix: string
    }

    viewer?: DocumentUploader | DocumentViewer
    ReactNativeWebView: {
      postMessage?(): void
    }
    parachuteSsrApp: {
      insets: {
        top: number
        bottom: number
      }
    }
    muiProLicenseKey?: string
  }
}

export type GlassboxDetector = {
  triggerCustomEvent: (eventName: string, data: string | number) => void
}

export type ChartNoteQuestion = {
  title: string
  text: string
  answer: {
    answerValue: string
  }
  answers: {
    value: string
    selected: string
  }[]
  questionId?: string
}

export type DmeOrderPermissions = {
  follow: boolean
  cancel: boolean
  comment: boolean
  internalComment: boolean
  supplierComment: boolean
  unlocked: boolean
  patientEditable: boolean
  mrnEditable: boolean
  update: boolean
  sendDocuments: boolean
  updateChartNotes: boolean
  updateDeliveryData: boolean
  updateManualDocumentation: boolean
  manuallySatisfyGeneratedDocs: boolean
  manageFulfillmentAgreements: boolean
  invite: boolean
  sidebar: {
    showTransferredOrderOriginalCart: boolean
  }
  supplierSystemPatientLinkage: {
    manage: boolean
    show: boolean
  }
  supplierFulfillments: {
    manage: boolean
    show: boolean
  }
}

export type CarrierMembership = {
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: string
  carrierName: string
  memberNumber: string
  enrollmentChangedAt: string
  enrollmentStatus: string
  rank?: string
  phoneNumber?: string
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
}

export type CarrierLineItemAuthorization = {
  id: string
  lineItemAuthorizationNumber: string
  hcpcs: string
  quantity: number
  startDate: string
  endDate: string
  withdrawn: boolean
}

export type CarrierAuthorizationDmeOrder = {
  id: string
  reorderable: boolean
  fulfillments: BasicFulfillment[]
  status: string
  permissions: {
    reorder: boolean
  }
}

export type CarrierAuthorization = {
  id: string
  carrierMembership: CarrierMembership
  carrierLineItemAuthorizations: CarrierLineItemAuthorization[]
  dmeOrders: CarrierAuthorizationDmeOrder[]
  terminated: boolean
  parentAuthorizationNumber: string
  authorizationNotes: string
  authStatus: string
  internalNotes: string
  requesterName: string
  requesterNpi: string
  facilityName: string
  facilityNpi: string
  supplierName: string
  supplierNpi: string
  diagnoses: string[]
  updatedAt: string
  status: string
  permissions: {
    [attr: string]: boolean
  }
}

export type EmploymentMention = {
  id: string
  name: string
  email: string
  employerName: string
  isFollower: boolean
}

export enum EmployerType {
  Supplier = "supplier",
  ClinicalFacility = "clinical_facility",
  None = "none",
  Doctor = "doctor",
  Payor = "payor",
  SupplierOrganization = "supplier_organization",
  ClinicalOrganization = "clinical_organization",
}

export type Employer = {
  employerType: EmployerType
  employerId: string
  multiEmployersInOrganization: boolean
  patientPortalEnabled?: boolean
}

export type Employment = {
  id: string
  externalId: string
  employerId: string
  employerName: string
  employerType: string
  role?: string
  salesRep?: boolean
}

export type ClinicalOrganizationsEmployment = {
  id: string
  externalId: string
  employerId: string
  employerName: string
  employerType: string
  facilityType?: string
  role?: string
}

export type User = {
  firstName: string
  lastName: string
  email?: string
  mobileNumber?: string
  phoneNumber?: string
  phoneExtension?: string
  timeZone?: string
  userId?: string
}

export type UiPreferences = {
  skippedConsignmentClosetSelections?: {
    supplierId: string
    clinicalFacilityId: string
  }[]
}

export type ContextEmployerType =
  | EmployerType.Supplier
  | EmployerType.ClinicalFacility
  | EmployerType.SupplierOrganization

export type ContextEmployer = {
  employerType: ContextEmployerType
  employerId: string
  multiEmployersInOrganization: boolean
  patientPortalEnabled: boolean
}

export type Context = {
  currentEmployer: ContextEmployer
  dmeOrderSpecific: boolean
  emrSession: boolean
  emrPatientContext: boolean
  requiresTwoStepDownload: boolean
  featureFlags: FeatureFlagInput
  companyFaxNumber: string
  uiPreferences: UiPreferences
  nonprodHeaderDetails: string
  showEmrResetPasswordBanner: boolean
  showJoinFacilityButton: boolean
}

export type DataGridPageArgs = {
  after?: string
  before?: string
  first?: string
  last?: string
}

export type DataGridOptions = {
  page: number
  sort?: ServerSideDataGridSort
  filterModel?: GridFilterModel
  pageArgs?: DataGridPageArgs
}

export type WorkflowPageContext = {
  isSidebarOpen?: boolean
  pollForDocuments: () => void
  registerPollForDocuments: (callback: () => void) => void
  unregisterPollForDocuments: () => void
  setSidebarTab: (sidebarTab: SidebarTab) => void
}

export type Document = {
  key: string
  title: string
  dmeOrderDocumentationRequirementId: string
}

export type SupplierLegalEntity = {
  name: string
  id: string
}

export type SupplierInformation = {
  name: string
  logoUrl?: string
}

type SignatureChannels = {
  email?: boolean
  sms?: boolean
  fax?: boolean
  epic?: boolean
  cerner?: boolean
}

type PreferredChannels = string[]

export type Address = {
  id: number
  line1: string
  line2: string
  room: string
  city: string
  state: string
  zip: string
  phoneNumber: string
  alternatePhoneNumber?: string
  addressType: AddressType
}

export enum DmeOrderWarningSeverity {
  Error = "error",
  Warning = "warning",
  Info = "info",
}

export interface SupplierLegalEntityMetadata {
  name: string
  faxNumber: string
  phoneNumber: string
}

export interface BasicSupplierWarningMetadata {
  supplierLegalEntity?: SupplierLegalEntityMetadata
  supplierNames: string[]
}

export interface PatientPreferenceWarningMetadata {
  lineItemGroupId: string
}

export interface RejectedSupplierWarningMetadata {
  supplierName: string
  packageConfigurationId: string
}

export enum ContactChannel {
  Email = "email",
  Fax = "fax",
  Sms = "sms",
  Print = "print",
  Epic = "epic",
  Cerner = "cerner",
  Unknown = "unknown",
}

export interface SignatureRequest {
  id: string
  createdAt: string
  contactChannel: ContactChannel
  sentAt: string
}

export type SignatureWarningType = "pending" | "manual" | "needed" | "invalid"

export interface SignatureWarningMetadata {
  doctor: Doctor
  signatureRequests: SignatureRequest[]
  type: SignatureWarningType
  nextExpectedDate: string | undefined
}

export interface PackageConfigurationWarningMetadata {
  packageConfigurationId: string
}

export interface NotAddablePackageConfigurationWarningMetadata {
  packageConfigurationId: string
}

export interface InsuranceWarningMetadata {
  errorType: InsuranceErrorType
}

export interface ManagerApprovalWarningMetadata {
  packageNames: string[]
}

export type InsuranceErrorType = "policy" | "carrier"

export type DmeOrderWarningMetadata =
  | BasicSupplierWarningMetadata
  | NotAddablePackageConfigurationWarningMetadata
  | PackageConfigurationWarningMetadata
  | PatientPreferenceWarningMetadata
  | RejectedSupplierWarningMetadata
  | SignatureWarningMetadata
  | InsuranceWarningMetadata
  | ManagerApprovalWarningMetadata

export type DmeOrderWarningKey =
  | "already_being_accepted"
  | "basic_supplier"
  | "billing_address"
  | "billing_phone_number"
  | "carrier_authorization_number"
  | "delivery_phone_number"
  | "diagnoses"
  | "discharge_date"
  | "manager_approval"
  | "medicare_signing_ability_unknown"
  | "not_addable_package_configuration"
  | "ordering_doctor"
  | "patient_preference"
  | "pecos_certification"
  | "primary_insurance_policy"
  | "products"
  | "product_requirements"
  | "proof_of_delivery"
  | "prevent_supplier_edit"
  | "rejected_supplier"
  | "rx_details"
  | "secondary_insurance_policy"
  | "signature_ready_documentation"
  | "signature_request_method"
  | "supplier_signature"
  | "supplier_system_clinical_facility_id"
  | "tertiary_insurance_policy"

export type DmeOrderWarning = {
  key: DmeOrderWarningKey
  severity: DmeOrderWarningSeverity
  metadata?: DmeOrderWarningMetadata
  step?: string
  text?: string
}

export type InternalStatus = {
  id: number
  status: string
  archivedAt?: string
}

export type InsuranceRank = "primary" | "secondary" | "tertiary"

export type InsurancePolicy = {
  active: boolean
  carrierId: string
  insuranceProductId: string
  carrierName: string
  checkPending: boolean
  memberNumber: string
  rank: InsuranceRank
  manual: boolean
  followsMedicare: boolean
  authorizationEnabled: boolean
  planId?: string
  hasPreferredSuppliers: boolean
  preferredSupplierStates: string[]
  preferredSupplierZips: string[]
  managedPlan: boolean //TODO @sarapiette deprecate
  managedPlanStates: string[] //TODO @sarapiette deprecate
  managedPlanZips: string[] //TODO @sarapiette deprecate
  id: string
}

export type Icd10Code = {
  code: string
  description: string
  codeLabel: string
  allow?: string
  deny?: string
  common?: string
}

export type ClinicalFacility = {
  id: string
  externalId: string
  gid: string
  name: string
  state: string
  authorizationEnabled: boolean
  catalogSearchType: CatalogSearchType
  alwaysShowRxDetails: boolean
  multiSupplier: boolean
  signedAgreements: boolean
  enterprise: boolean
  formularyPriceEnabled: boolean
  oneTimeOrderEnabled: boolean
  patientPortalEnabled: boolean
  usesEnterpriseFeatures: boolean
  tempFacilityOrDoctorOffice: boolean
  yourOrganizationsSuppliersOnlyDefault: boolean
  liaison: boolean
}

export enum CatalogSearchType {
  SkuQuickAdd = "sku_quick_add",
  PackageFilter = "package_filter",
}

export enum CatalogSearchTab {
  SkuQuickAdd = "SKU Lookup",
  PackageFilter = "Product Search",
}

export enum CatalogBrowseTab {
  Supplier = "Supplier",
  Product = "Product",
}

export enum SearchWorkflow {
  SupplierFirstSearch = "supplier_first_search",
  SearchByProduct = "product_first_search",
}

export enum PaymentMethod {
  Insurance = "insurance",
  PrivatePay = "private_pay",
  Hospice = "hospice",
  Pps = "pps",
  Authorization = "authorization",
}

export enum InsurancePlanType {
  Medicare = "medicare",
  Medicaid = "medicaid",
  MedicareMedicaid = "medicare_medicaid",
  Commercial = "commercial",
  WorkersComp = "workers_comp",
  Chip = "chip",
}

export enum ServiceTier {
  Standard = "standard",
  Basic = "basic",
  FreemiumBase = "freemium_base",
}

export enum NetworkCoverage {
  Unknown = "unknown",
  InNetwork = "in_network",
  OutOfNetwork = "out_of_network",
  PrimaryOutOfNetwork = "primary_out_of_network",
}

export type ConsignmentCloset = {
  externalId: string
  name: string
  floor: Nullable<string>
}

export interface Supplier {
  externalId: string
  name: string
  logoUrl: string | undefined
  serviceTier: ServiceTier
  intakeFaxNumber: string
  patientPortalEnabled: boolean
}

export interface PreferredSupplier {
  supplier: Supplier
  rank: number | null
}

export type PatientSnapshot = {
  id: number
  heightInches: number
  weightLbs: number
  preferredLanguage: string
  preferredName: string
  email: string
  mrn: string
}

export enum OfferingType {
  Equipment = "equipment",
  Service = "service",
  O2Recert = "o2_recert",
}

export enum DocumentationRequirementSatisfyMethod {
  Generate = "generate",
  Manual = "manual",
}

export enum DocumentationRequirementSatisfyStatus {
  Unstarted = "unstarted",
  Incomplete = "incomplete",
  Complete = "complete",
  Invalid = "invalid",
  FaxInProgress = "fax_in_progress",
}

export enum DocumentationRequirementEntryMechanism {
  Upload = "UPLOAD",
  DownloadUpload = "DOWNLOAD_UPLOAD",
  Survey = "SURVEY",
  FillablePdf = "FILLABLE_PDF",
}

type BaseDocumentationRequirementFields = {
  externalId: string
  title: string
  categoryName: string | null
  usageContextTitle: string | null
  requested: boolean
  generate: boolean
  satisfyStatus: DocumentationRequirementSatisfyStatus
  chartNotesRequired: boolean
  definitionIdentifierName: string | null
  generatorType?: string
}

export type GenerateDocumentationRequirement = BaseDocumentationRequirementFields & {
  satisfyMethod: DocumentationRequirementSatisfyMethod.Generate
  entryMechanism:
    | DocumentationRequirementEntryMechanism.Survey
    | DocumentationRequirementEntryMechanism.FillablePdf
  satisfyStatus:
    | DocumentationRequirementSatisfyStatus.Incomplete
    | DocumentationRequirementSatisfyStatus.Complete
    | DocumentationRequirementSatisfyStatus.Invalid
    | DocumentationRequirementSatisfyStatus.Unstarted
}

type ManualDocumentationRequirement = BaseDocumentationRequirementFields & {
  satisfyMethod: DocumentationRequirementSatisfyMethod.Manual
  entryMechanism:
    | DocumentationRequirementEntryMechanism.Upload
    | DocumentationRequirementEntryMechanism.DownloadUpload
  satisfyStatus:
    | DocumentationRequirementSatisfyStatus.Incomplete
    | DocumentationRequirementSatisfyStatus.Complete
    | DocumentationRequirementSatisfyStatus.FaxInProgress
}

export type DocumentationRequirement =
  | ManualDocumentationRequirement
  | GenerateDocumentationRequirement

export interface DocumentationRequirementPermissions {
  updateChartNotes: boolean
  updateManualDocumentation: boolean
}

export type EmergencyContact = {
  id?: string
  firstName?: string
  lastName?: string
  relationship?: string
  phoneNumber?: string
  preferredLanguage?: string
  email?: string
}

export type Fulfillment = {
  id: string
  deliveryAddressId: number
  lineItemGroupIds: string[]
  deliveryDate: string
  expectedDeliveryDate: string
  actualDeliveryDate: string
  deliveryNote: string
  deliveryPhoneNumber: string
  deliveryStatus: string
  hasDeliveredStatus: boolean
  isReadyForDelivery: boolean
  isConsignmentCloset: boolean
  consignmentCloset: ConsignmentCloset
  offeringType?: OfferingType
  supplierFulfillment?: SupplierFulfillment
}

export type SupplierFulfillment = {
  id: string
  supplierSystemId: string
}

export type LineItem = {
  id: string
  skuDescription: string
  hcpcs: string
  utilizationUnitValue: number
  totalQuantityWithUtilization: number
  utilizationUnitLabel: string
  quantity: number
  formularyPrice: Nullable<string>
}

export type LineItemGroup = {
  id: string
  packageName: string
  dmeOrderFulfillmentId: string
  packageConfigurationId: string
  packageId: string
  imageUrl: string | null
  packageSupplier: Supplier
  quantity: number
  isConsignmentCloset: boolean
  consignmentCloset: ConsignmentCloset
  offeringType?: OfferingType
  lineItems: LineItem[]
  patientPreference: boolean
  activeCustomAttributeConfigurationId: number
  paymentMethod: PaymentMethod
  catalogId: string
}

export enum ChartNoteStatus {
  Qualified = "Complete",
  InProgress = "Incomplete",
  Disqualified = "Invalid",
  Unstarted = "Not Started",
}

export type ChartNote = {
  externalId: string
  name: string
  status: ChartNoteStatus
  id: string
  rxDetailsComplete: boolean
  submittable: boolean
  lineItemGroupIds: string[]
  hcpcs: string[]
}

export type QuestionnaireResponse = {
  id: string
  dmeOrderId: string
  title: string
  status: string //TODO make this an enum
  lineItemGroupId: string
  rxDetailsComplete: boolean
  permissions: {
    show: boolean
    manage: boolean
  }
  hcpcs: string
  submittable: boolean
}

export type Doctor = {
  npi: string
  firstName: string
  lastName: string
  phoneNumber: string
  maskedMobileNumber: string
  faxNumber: string
  maskedEmail: string
  doctorId: string
  cacheSignaturesCounts: {
    fax: number
    sms: number
    email: number
    epic: number
    cerner: number
  }
  state?: string
  city?: string
  pecosCertified: boolean
  signatureCount: number
  clinicalOrganizations: string[]
  credential?: string
}

export type Page = {
  id: string
  checked: boolean
  active?: boolean
  title: string
}

export type DiagnosisAllow = {
  name: string
  valid: boolean
}

export type DiagnosisDeny = {
  code: string
  codeLabel: string
  items: string[]
  warning: string
}

export type DiagnosisWarning = {
  name: string
  warnings: DiagnosisWarningItem[]
}

type DiagnosisWarningItem = {
  reason: string
  severity: string
  codes?: string[]
}

export type Diagnosis = {
  icd10Codes: Icd10Code[]
  limit: number
  allow: DiagnosisAllow[]
  deny: DiagnosisDeny[]
  warnings: DiagnosisWarning[]
}

export type MasterPatient = {
  id: string
  sex: string
  dateOfBirth: string
  firstName: string
  lastName: string
}

export type HospicePatient = {
  id: string
  hospiceStatus: string
  hospiceEpisodeAdmitDate: string
  hospiceEpisodeDischargeDate: string
}

export type Patient = {
  id: string
  sex: string
  dateOfBirth: string
  age: number
  firstName: string
  lastName: string
  masterPatientId: string
  supplierSystemPatientId: string
}

export type EmrInstance = {
  emrMessagingEnabled: boolean
}

export type EmrCachedPatients = EmrCachedPatient[]

export type EmrCachedPatient = {
  mrn: string
  syncedAt?: string
  encounter: EmrCachedEncounter
  externalId: string
  patientId: string
  address: Address
  billingPhoneNumber: string
  alternateBillingPhoneNumber?: string
  email: string
  emergencyContactFirstName: string
  emergencyContactLastName: string
  emergencyContactPhoneNumber: string
  emergencyContactRelationship: string
  primaryLanguage: string
  primaryInsuranceName: string
  primaryInsuranceMemberNumber: string
  secondaryInsuranceName: string
  secondaryInsuranceMemberNumber: string
  tertiaryInsuranceName: string
  tertiaryInsuranceMemberNumber: string
  weightLbs: number
  heightInches: number
  displayableDiagnoses: string[]
  notes: string
  observations: EmrCachedObservation[]
  doctors: EmrCachedPatientDoctor[]
}

export type EmrCachedEncounter = {
  id: string
  visitNumber: string
  syncedAt?: string
  admittedAt: string
  dischargedAt: string
  patientType: string
  accountStatus: string
  room: string
  assignedPatientLocation: string
  displayableDiagnoses: string[]
}

export type EmrCachedObservation = {
  name: string
  value: string
  effectiveAt: string
}

export type EmrCachedPatientDoctor = {
  name: string
  npi: string
  relationship: string
}

export type RecentOrderLineItemGroup = {
  name: string
  isConsignmentCloset: boolean
}

export type RecentOrder = {
  id: string
  clinicalFacilityName: string
  creatorName: string
  providerOriginator: boolean
  supplierNames: string
  products: RecentOrderLineItemGroup[]
  status: string
  statusClass: string
  signatureStatus: SignatureStatus
  isProgressVisible: boolean
  isStatusVisible: boolean
  percentageComplete?: number
  reorderable: boolean
  carrierAuthorizationNumber?: string
  permissions: {
    reorder: boolean
  }
  orderStatus: OrderStatus
  fulfillments: BasicFulfillment[]
  createdAt: string
}

export type FollowersGroup = {
  name: string
  followers: Follower[]
}

export enum FollowerEmployerType {
  Supplier = "Supplier",
  ClinicalFacility = "ClinicalFacility",
}

export type Follower = {
  externalId: string
  name: string
  id: string
  employerType: FollowerEmployerType
}

export enum OrderType {
  HospiceOrder = "Hospice",
  ManualDocumentationOrder = "Manual Documentation",
  PpsOrder = "PPS",
  PrivatePayOrder = "Private Pay",
  AuthorizationOrder = "Pre-Authorization",
  QuickOrder = "Quick",
  Reorder = "Re-Order",
  ConsignmentCloset = "Consignment",
  O2Recert = "O2 Recert",
}

export enum OrderStatus {
  FacilityInProgress = "facility_in_progress",
  SupplierReview = "supplier_review",
  Accepted = "accepted",
  Canceled = "canceled",
  Transferred = "transferred",
}

export enum SignatureStatus {
  Needed = "needed",
  NotNeeded = "not_needed",
  Completed = "completed",
  Pending = "pending",
  Unknown = "unknown",
}

export enum DeliveryStatus {
  ThirdPartyDelivery = "third_party_delivery",
  DeliverySuccessful = "delivery_successful",
  Completed = "completed",
  ConfirmationInProgress = "confirmation_in_progress",
  ContactingPatient = "contacting_patient",
  PartiallyShipped = "partially_shipped",
  PendingDeliveryTicket = "pending_delivery_ticket",
  PendingFurtherReview = "pending_further_review",
  Processing = "processing",
  ReadyForDelivery = "ready_for_delivery",
  ReturnComplete = "return_complete",
  SchedulingDelivery = "scheduling_delivery",
  SubmittedToBasicServiceTierSupplier = "submitted_to_basic_service_tier_supplier",
  HeldForCopay = "held_for_copay",
  HeldForDocumentation = "held_for_documentation",
  HeldForDocumentationAndCopay = "held_for_documentation_and_copay",
  PendingAuthorization = "pending_authorization",
  ReturnInProgress = "return_in_progress",
  Backordered = "backordered",
  Canceled = "canceled",
  CannotDispense = "cannot_dispense",
  DeliveryUnsuccessful = "delivery_unsuccessful",
  PatientRefused = "patient_refused",
}

export enum SignatureType {
  Digital = "digital",
  Manual = "manual",
  OnScreen = "onscreen",
}

export type DmeOrder = {
  id: string
  creatorName: string
  billingAddressId: Nullable<number>
  billingAddressRequired: boolean
  billingPhoneNumber: string
  carrierAuthorizationId: string
  carrierAuthorizationNumber: string
  createdAt: string
  createdVia: DmeOrderCreatedVia
  discharge: boolean
  dischargeDate?: string
  documentationRequirements: DocumentationRequirement[]
  everTransferred: boolean
  transferredOrderLocked: boolean
  orderDate?: string
  orderTypes: OrderType[]
  orderStatus: OrderStatus
  signatureStatus: SignatureStatus
  reorderable: boolean
  signatureType: SignatureType
  useFeetForHeight: boolean
  useSnapshot: boolean
  diagnosis: Diagnosis
  doctor?: Doctor
  supplier?: Supplier
  patient: Patient
  patientSnapshot: PatientSnapshot
  permissions: DmeOrderPermissions
  signatureChannels: SignatureChannels
  preferredChannels?: PreferredChannels
  addresses: Address[]
  chartNotes: ChartNote[]
  clinicalFacility: ClinicalFacility
  emergencyContacts: EmergencyContact[]
  followers: Follower[]
  fulfillments: Fulfillment[]
  icd10Codes: Icd10Code[]
  insurancePolicies: InsurancePolicy[]
  lineItemGroups: LineItemGroup[]
  documentIds: string[]
  showCarrierAuthorizationNumber: boolean
  restrictToDigitalSignature: boolean
  currentSignature?: DmeOrderSignature
  supplierCobrowseKey: string
  supplierFaxNumber: string
  supplierLegalEntity: SupplierLegalEntity
  transferred: boolean
  supplierGeneratedPriorToPushBack: boolean
  mostRecentSupplierTransfer?: DmeOrderSupplierTransfer
  brightreeSupplier: boolean
  latestPatientPortalEventTimestamp: string
  optum: boolean
  questionnaireResponses?: QuestionnaireResponse[]
  cachedEncounter?: EmrCachedEncounter
  snoozed: boolean
  minSnoozeDate?: string
  maxSnoozeDate?: string
  originator?: Originator
}

export enum Originator {
  Csr = "csr",
  Provider = "provider",
}

export type BasicFulfillment = {
  deliveryStatus?: DeliveryStatus
  deliveryDate?: string
  actualDeliveryDate?: string
  expectedDeliveryDate?: string
}

export enum EventAction {
  AcceptedComplete = "submission_complete",
  AcceptanceError = "acceptance_error",
  ActualDeliveryDateUpdate = "actual_delivery_date_update",
  Cancel = "cancel",
  ChangeClinicalFacility = "change_clinical_facility",
  Change = "change",
  Comment = "comment",
  Create = "create",
  DeliveryStatusUpdate = "delivery_status_update",
  DocAttached = "doc_attached",
  DocDownload = "doc_download",
  DocGenerated = "doc_generated",
  DocIngest = "doc_ingest",
  DocOutdated = "doc_outdated",
  DocRemoved = "doc_removed",
  DocUpload = "doc_upload",
  ExpectedDeliveryDateUpdate = "expected_delivery_date_update",
  FollowUpOwner = "follow_up_owner_update",
  InternalComment = "internal_comment",
  InvitationGenerated = "invitation_generated",
  PatientMessage = "patient_message",
  PatientPortalAutomated = "patient_portal_automated",
  PullBack = "pull_back",
  Reorder = "reorder",
  SignatureComplete = "authorization_complete",
  SignatureDenied = "signature_denied",
  SignatureRequest = "authorization_request",
  SignatureRequestBatchCreated = "signature_request_batch_created",
  SignedDocUpload = "signed_doc_upload",
  Submit = "provider_submit",
  SupplierComment = "supplier_comment",
  SupplierEdit = "supplier_edit",
  SupplierReject = "supplier_reject",
  SupplierSendBack = "doc_change_request",
  SupplierSendBackInstructions = "supplier_send_back_instructions",
  SupplierUnasccept = "supplier_unaccept",
  TrackingNumberUpdate = "tracking_number_update",
}

export type ExternalLink = {
  text: string
  url?: string
}

export type SidebarLink = {
  tab: SidebarTab
  text: string
}

export type Event = {
  action: EventAction
  userId: string
  userName: string
  userPhoneNumber?: string
  userPhoneExtension?: string
  userEmail: string
  userEmployerName: string
  userEmployerType: string
  showContactInfo: boolean
  superAdmin: boolean
  content: string
  rawContent: string
  data: {
    employmentMentions?: EmploymentMention[]
  }
  skipNotifications?: boolean
  eventTimestamp: string
  externalLinks: ExternalLink[]
  sidebarLinks: SidebarLink[]
  readAt?: Nullable<string>
}

export type RxDetailsOutput = {
  label: string
  value: string
  lineItemGroupId: string
}

export type DmeOrderSupplierTransfer = {
  unmatchedLineItemGroups: LineItemGroup[]
  matchedLineItemGroups: LineItemGroup[]
}

export type DmeOrderSnapshotField = {
  label: string
  value?: string
}

type DmeOrderSnapshotSection = {
  section: string
  fields: DmeOrderSnapshotField[]
}

export type DmeOrderSnapshot = DmeOrderSnapshotSection[]

export type DmeOrderSignature = {
  doctor: Doctor
  signedAt: string
  signatureType: string
}

export type FeatureFlagName =
  | "activationFacilitySupportPage"
  | "additionalFaxPrompt"
  | "collectFacilityCancelReason"
  | "combinedCatalogSearch"
  | "contextDrivenDocs"
  | "createO2Recerts"
  | "demoClinicalGuidelines"
  | "draftDocMappings"
  | "explicitSupplierSelectionStep"
  | "facilityIncomingOrders"
  | "facilityIncomingOrdersDisplayRequiredActions"
  | "facilityIncomingOrdersToActionRequired"
  | "facilitySnoozeOrders"
  | "formularyPricing"
  | "incomingOrdersEnabledSetting"
  | "leftSidebarSignatureRequestsSignup"
  | "marketplacePackageConfiguration"
  | "multiSupplierTransfer"
  | "nativeSearch"
  | "newPatientLoadingForm"
  | "newPillIncomingOrders"
  | "optumEnhancedClinicalGuidelines"
  | "payorEnhancedClinicalGuidelines"
  | "payorUserActions"
  | "quickList"
  | "reactFacilityDashboard"
  | "referralApiPendingOrderEnabled"
  | "referralApiProductSelectionEnabled"
  | "renameRecertificationToRenewal"
  | "renameWopdToPrescription"
  | "shareRequestLink"
  | "signUpModal"
  | "signaturePad"
  | "stubbedClinicalGuidelines"
  | "surveyToDocValidator"
  | "ssrAppSignatureRequestViaEmail"
  | "useFunctionalConfigurationComponent"
  | "userActivationProductFirstSearch"
  | "userActivationProductFirstSearchIncludeEnterprise"
  | "userActivationSearchByProductTabDefault"

export type FeatureFlagInput = Partial<Record<FeatureFlagName, boolean>>

export enum DefaultStep {
  Any = "any",
}

export type Step = number | DefaultStep.Any

export enum Severity {
  Default = "default",
  Success = "success",
  Info = "info",
  Accent = "accent",
  Warning = "warning",
  Danger = "danger",
  Waiting = "waiting",
  Locked = "locked",
  Ready = "ready",
  Snoozed = "snoozed",
}

export type SurveyAnswerValue = string | number | string[]

export type SurveyOption = {
  label: string
  value: string
  callout?: React.ReactElement
  addOn?(params: { form; field }): React.ReactElement
  addOnPosition?: string
}

export enum SurveyAnswerType {
  Na = "na",
  Value = "value",
}

export type SurveyQuestion = {
  surveyId?: string
  displayStyle?: "inline"
  type: string
  text?: string
  title?: string
  answerValue?: SurveyAnswerValue
  defaultChoices?: SurveyAnswerValue[]
  questionId: string
  tooltips?: {
    subject: string
    body: string
  }[]
  choices?: {
    code: string
    label: string
  }[]
  other?: string
  answerType?: "value" | "na"
  naEnabled?: boolean
  naLabel?: string | boolean
  minimumLength?: number
  maximumLength?: number
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type FacilityOption = {
  id: string
  name: string
  inactive?: boolean
  userCount: number
  source: string
  supplierSystemId?: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
}

export type CsrInboxDocument = {
  id: string
  state: CsrInboxDocumentState
  clinicalFacilityName: string
  clinicalFacilityId: string
  createdAt: string
  assignee: Assignee
  internalStatus: InternalStatus
  documentId: string
  pageCount: number
  receivingFaxNumber: string
  source: string
  sourceType: string
  lastAccessUserName: string
  patientName?: string
}

export type Assignee = {
  name: string
  id: string
}

export enum CsrInboxDocumentState {
  New = "new",
  Assigned = "assigned",
  Discarded = "discarded",
  Forwarded = "forwarded",
  Emailed = "emailed",
  Pushed = "pushed",
  PushPending = "push pending",
}

export enum AddressType {
  Billing = "billing",
  Facility = "facility",
  Emergency = "emergency",
  Other = "other",
  Empty = "",
}

export interface SearchResult {
  label: string
  value: string
}

export interface Comment {
  content: string
  contentMentionIds: string[]
  rawContent: string
}

export interface Notice {
  content: string
  type: string
}

export enum TitleStatus {
  OrderAccepted = "order_accepted",
  OrderCanceled = "order_canceled",
  ReadyToAccept = "ready_to_accept",
  ReadyToSend = "ready_to_send",
  ActionRequired = "action_required",
  ActionRecommended = "action_recommended",
  FacilityWorkingOrder = "facility_working_order",
  SupplierReview = "supplier_review",
  InformationNeeded = "information_needed",
  TransferredSupplierView = "transferred_supplier_view",
  TransferredFacilityView = "transferred_facility_view",
}

export type Prefix = "fas" | "far" | "fal" | "fad" | "fab"

export enum EmrInstanceTypes {
  Cerner = "cerner",
  CernerR4 = "cerner_r4",
  EpicDstu2 = "epic_dstu2",
  EpicR4 = "epic_r4",
  PointClickCare = "point_click_care",
}

type ValidationError = {
  errors?: {
    [key: string]: string[]
  }
}

export enum SalesRepReassignmentSubmissionStatus {
  Unsubmitted = "unsubmitted",
  Success = "success",
  Error = "error",
}

export enum SignUpSource {
  Preferences = "preferences_self_signup",
  Sidebar = "sidebar_self_signup",
  Email = "email_self_signup",
}

export type ApplicationError = ValidationError & Error

export interface ClientsideInstrumentationWindow {
  commitSHA: string
  commitSHAWithPrefix: string
  releaseStage: string
}

export interface DatadogConfigurationWindow {
  applicationId: string
  clientToken: string
  site: string
  serviceName: string
  realUserMonitoring: {
    sampleRate: number
    sessionReplaySampleRate: number
  }
}

export interface ParachuteWindow {
  emrContextIndex?: string
  emrInstanceType?: EmrInstanceTypes
  employerType?: string
  employerId?: string
  currentUserId?: string
  currentUserEmail?: string
  currentUserName?: string
  currentOrderId?: string
  authenticity_token?: string
  featureFlags?: FeatureFlagInput
}

export enum CommentType {
  Internal = "internal",
  Supplier = "supplier",
  Global = "global",
}
