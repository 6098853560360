import React from "react"
import { Doctor } from "sharedTypes"
import ReviewCard from "../ReviewCard"
import * as styles from "./index.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import signOnScreen from "sign-on-screen.png"
import wave from "wave.png"
import { createReviewerEmployment } from "../../api"
import { navigate } from "utilities/navigation"
import { handleError } from "utilities/error"
import cx from "classnames"

type Props = {
  doctor: Doctor
}

const ReviewerSignUpPromo = ({ doctor }: Props) => {
  const doctorName = doctor.firstName + " " + doctor.lastName
  const onClick = () => {
    createReviewerEmployment(doctor.doctorId)
      .then((response) => {
        navigate(response.redirectUrl)
      })
      .catch((error) => {
        handleError(error)
      })
  }
  return (
    <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
      <ReviewCard
        className={styles.promoCard}
        title="Signature Requests"
        expanded
      >
        <div className={styles.promoContentContainer}>
          <img
            className={styles.promoContentImage}
            src={signOnScreen}
            alt="Sign On Screen"
            width="158"
          />
          <div className={styles.promoContentText}>
            <h3 className={styles.promoContentHeading}>
              Say goodbye to faxes, and hello to digital{" "}
              <span className="d-inline-block">
                renewals <img src={wave} width="16" alt="hand wave" />
              </span>
            </h3>
            <br />
            <p className={styles.promoContentBody}>
              Easily prepare orders for clinicians to Sign-On-Screen, or deny
              with a note to supplier.
            </p>
          </div>
        </div>
        <CanopyButton fullWidth onClick={onClick}>
          {`Review Signature Requests for ${doctorName}`}
        </CanopyButton>
        <p className="canopy-pbs-4x text-center canopy-typography-body-xsmall italic">
          Selected clinicians or associated admins will be notified that you've
          become a reviewer.
        </p>
      </ReviewCard>
    </div>
  )
}

export default ReviewerSignUpPromo
