import React, { useEffect, useState } from "react"
import CancelButton from "../CancelButton"
import SnoozeButton from "../SnoozeButton"
import UnsnoozeButton from "../UnsnoozeButton"
import FollowersButton from "../FollowersButton"
import { DmeOrder, DmeOrderPermissions, History, Page } from "sharedTypes"
import {
  PatientFormParams,
  SidebarTab,
  UpdateFollowersParams,
} from "../../sharedTypes"
import PatientModal from "../PatientModal"
import FollowersModal from "../FollowersModal"
import BottomNavigation from "../BottomNavigation"
import LeftNavigation from "../LeftNavigation"
import TopNavigation from "../TopNavigation"
import PatientLink from "../PatientLink"
import CanopyBackedFeedbackButton from "components/FeedbackModalButton/CanopyBackedFeedbackButton"
import * as styles from "../LeftNavigation/index.module.scss"

type Props = {
  nextPagePath: string
  pages: Page[]
  history: History
  permissions: DmeOrderPermissions
  dmeOrder: DmeOrder
  patientLinkDisabled: boolean
  updatePatient(id: string, params: PatientFormParams): Promise<void>
  updateFollowers(params: UpdateFollowersParams): Promise<void>
  toggle(): void
  close(): void
  inOrderNavReplacement: boolean
  currentTab: SidebarTab
  setSidebarTab(tab: SidebarTab): void
  isFacilityUser?: boolean
  facilitySnoozeOrders?: boolean
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
}

function getButtons({
  classes,
  followers,
  onFollowersClick,
  permissions,
  dmeOrder,
  isFacilityUser,
  facilitySnoozeOrders,
  unsnoozeDmeOrder,
  setShowSnoozeModal,
  showSnoozeModal,
  snoozeDmeOrder,
}) {
  const facilityInProgressOrder =
    dmeOrder.orderStatus === "facility_in_progress"
  const viewSnoozeButton =
    facilitySnoozeOrders &&
    facilityInProgressOrder &&
    isFacilityUser &&
    !dmeOrder.snoozed
  const viewUnsnoozeButton =
    facilitySnoozeOrders &&
    facilityInProgressOrder &&
    isFacilityUser &&
    dmeOrder.snoozed
  return [
    <FollowersButton
      key="followers-button"
      className={classes.followers}
      followers={followers}
      onClick={onFollowersClick}
    />,
    viewSnoozeButton && (
      <SnoozeButton
        key="snooze-button"
        className={classes.action}
        showSnoozeModal={showSnoozeModal}
        setShowSnoozeModal={setShowSnoozeModal}
        dmeOrder={dmeOrder}
        snoozeDmeOrder={snoozeDmeOrder}
      />
    ),
    viewUnsnoozeButton && (
      <UnsnoozeButton
        unsnoozeDmeOrder={unsnoozeDmeOrder}
        key="snooze-button"
        className={classes.action}
      />
    ),
    permissions.cancel && (
      <CancelButton
        key="cancel-button"
        className={classes.action}
        originator={dmeOrder.originator}
      />
    ),
  ].filter(Boolean)
}

const InternalNavigation: React.FC<Props> = ({
  nextPagePath,
  pages,
  history,
  permissions,
  dmeOrder,
  patientLinkDisabled,
  updatePatient,
  updateFollowers,
  toggle,
  close,
  inOrderNavReplacement,
  currentTab,
  setSidebarTab,
  isFacilityUser,
  facilitySnoozeOrders,
  unsnoozeDmeOrder,
  snoozeDmeOrder,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [showFollowersModal, setShowFollowersModal] = useState<boolean>(false)
  const [showPatientModal, setShowPatientModal] = useState<boolean>(false)
  const [showSnoozeModal, setShowSnoozeModal] = useState<boolean>(false)

  useEffect(() => {
    const onClose = () => {
      setOpen(false)
      close()
    }

    history.listen(onClose)
  }, [history, close])

  const closeFollowersModal = () => {
    setOpen(true)
    setShowFollowersModal(false)
  }

  const onEditPatientClick = () => {
    if (patientLinkEditable()) {
      setOpen(false)
      setShowPatientModal(true)
    }
  }

  const closePatientModal = () => {
    setOpen(true)
    setShowPatientModal(false)
  }

  const onUpdatePatient = (params: PatientFormParams) => {
    const patientId = dmeOrder.patient.id
    return updatePatient(patientId, params).then(() => {
      setOpen(true)
      setShowPatientModal(false)
    })
  }

  const onToggle = () => {
    setOpen(!open)
    toggle()
  }

  const onFollowersClick = () => {
    setShowFollowersModal(true)
    setOpen(false)
  }

  const patientLinkEditable = () => {
    return (
      permissions.patientEditable ||
      permissions.mrnEditable ||
      permissions.supplierSystemPatientLinkage.manage
    )
  }

  const patientLink = () => {
    return (
      <PatientLink
        disabled={patientLinkDisabled}
        editable={patientLinkEditable()}
        supplierPatientIdVisible={permissions.supplierSystemPatientLinkage.show}
        dmeOrder={dmeOrder}
        onClick={onEditPatientClick}
      />
    )
  }

  const { followers } = dmeOrder

  return (
    <>
      {inOrderNavReplacement && (
        <TopNavigation
          currentTab={currentTab}
          setSidebarTab={setSidebarTab}
          showTransferTab={
            dmeOrder.permissions.sidebar.showTransferredOrderOriginalCart
          }
          dmeOrder={dmeOrder}
        />
      )}
      <div className="workflow-left-nav nav-primary">
        <div
          className={`d-none d-lg-flex flex-column justify-content-between ${styles.container}`}
        >
          <div>
            <LeftNavigation
              buttons={getButtons({
                classes: {
                  followers: styles.transparent,
                  action: styles.transparent,
                },
                followers,
                onFollowersClick,
                permissions,
                dmeOrder,
                isFacilityUser: isFacilityUser,
                facilitySnoozeOrders: facilitySnoozeOrders,
                unsnoozeDmeOrder,
                showSnoozeModal,
                setShowSnoozeModal,
                snoozeDmeOrder,
              })}
              patientLink={patientLink()}
              pages={pages}
              nextPagePath={nextPagePath}
            />
          </div>
          <CanopyBackedFeedbackButton />
        </div>
        <div className="d-block d-lg-none">
          <BottomNavigation
            open={open}
            buttons={getButtons({
              classes: {
                followers: "",
                action: "",
              },
              followers,
              onFollowersClick,
              permissions,
              dmeOrder,
              isFacilityUser: isFacilityUser,
              facilitySnoozeOrders: facilitySnoozeOrders,
              unsnoozeDmeOrder,
              showSnoozeModal,
              setShowSnoozeModal,
              snoozeDmeOrder,
            })}
            dmeOrder={dmeOrder}
            patientLink={patientLink()}
            pages={pages}
            nextPagePath={nextPagePath}
            toggle={onToggle}
          />
        </div>
        {showFollowersModal && (
          <FollowersModal
            show={showFollowersModal}
            closeModal={closeFollowersModal}
            dmeOrder={dmeOrder}
            updateFollowers={updateFollowers}
            editable={permissions.follow}
          />
        )}
        {showPatientModal && (
          <PatientModal
            show={showPatientModal}
            cancel={closePatientModal}
            dmeOrder={dmeOrder}
            updatePatient={onUpdatePatient}
            demographicsEditable={permissions.patientEditable}
            mrnEditable={permissions.mrnEditable}
            supplierPatientIdEditable={
              permissions.supplierSystemPatientLinkage.manage
            }
            supplierPatientIdVisible={
              permissions.supplierSystemPatientLinkage.show
            }
          />
        )}
      </div>
    </>
  )
}

export default InternalNavigation
