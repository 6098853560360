import { DocumentationRequirementSatisfyStatus, Severity } from "sharedTypes"
import classNames from "classnames"
import SeverityIcon from "components/SeverityIcon"
import React from "react"

export const DocumentationRequirementStatus = ({ satisfyStatus }) => {
  const statusToSeverity = {
    [DocumentationRequirementSatisfyStatus.Unstarted]: Severity.Warning,
    [DocumentationRequirementSatisfyStatus.Incomplete]: Severity.Warning,
    [DocumentationRequirementSatisfyStatus.Complete]: Severity.Success,
    [DocumentationRequirementSatisfyStatus.Invalid]: Severity.Danger,
  }

  const statusToText = {
    [DocumentationRequirementSatisfyStatus.Unstarted]: "Not Started",
    [DocumentationRequirementSatisfyStatus.Incomplete]: "Incomplete",
    [DocumentationRequirementSatisfyStatus.Complete]: "Complete",
    [DocumentationRequirementSatisfyStatus.Invalid]: "Invalid",
    [DocumentationRequirementSatisfyStatus.FaxInProgress]: "Fax in Progress",
  }

  const status = statusToText[satisfyStatus]
  const severity = statusToSeverity[satisfyStatus]

  return (
    <div className={classNames("pill nowrap", `pill-${severity}`)}>
      <SeverityIcon className="canopy-mie-2x" type={severity} />
      {status}
    </div>
  )
}
