import React, { useEffect, useState } from "react"
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { SupplierInsuranceExternalIdUpsert } from "../../graphql/supplierInsuranceExternalIdUpsertMutation"

interface Props {
  cellParams: GridRenderCellParams
}

export const ExternalInsuranceInput: React.FC<Props> = ({ cellParams }) => {
  const defaultExternalId = !!cellParams.row.externalInsuranceMapping
    ? cellParams.row.externalInsuranceMapping.externalSystemId
    : ""
  const [upsertStatus, setUpsertStatus] = useState<any>()
  const [upsertExternalSystemId, { loading }] = useMutation(
    SupplierInsuranceExternalIdUpsert,
    {
      onCompleted: () => {
        setUpsertStatus({ message: "Saved", status: "success" })
      },
      onError: () => {
        setUpsertStatus({
          message: "Error saving",
          status: "error",
        })
      },
    }
  )

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined
    if (upsertStatus) {
      timeout = setTimeout(() => {
        setUpsertStatus(undefined)
      }, 2000)
    }
    return () => clearTimeout(timeout)
  }, [upsertStatus, setUpsertStatus])

  const validateFields = ({ externalSystemId }) => {
    const errors: { externalSystemId?: string } = {}
    const onlyDigits = /^\d+$/
    if (!externalSystemId || onlyDigits.test(externalSystemId)) {
    } else {
      errors.externalSystemId = "Numbers only"
    }
    return errors
  }

  const onSubmit = async ({ externalSystemId }) => {
    await upsertExternalSystemId({
      variables: {
        insuranceProductId: cellParams.row.externalId,
        externalSystemId: externalSystemId,
      },
    })
  }

  return (
    <Formik
      initialValues={{ externalSystemId: defaultExternalId }}
      onSubmit={onSubmit}
      validate={(values) => validateFields(values)}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        isValidating,
        isSubmitting,
        dirty,
      }) => {
        const disableSubmit =
          (!values.externalSystemId && !defaultExternalId) ||
          (!isValid && dirty) ||
          values.externalSystemId === defaultExternalId

        return (
          <div className="d-flex canopy-pbs-4x">
            <CanopyTextInputField
              label="External System ID"
              type="text"
              id="externalSystemId"
              name="externalSystemId"
              hiddenLabel={true}
              size="small"
              value={values.externalSystemId}
              onChange={handleChange}
              onFocus={(e) => {
                e.stopPropagation()
                setUpsertStatus(undefined)
              }}
              onClick={(e) => e.stopPropagation()}
              onBlur={handleBlur}
              required
              feedbackMessageStatus={upsertStatus?.status}
              feedbackMessage={
                errors.externalSystemId?.toString() || upsertStatus?.message
              }
            />
            <CanopyButton
              className="canopy-mx-4x"
              data-testid={`save-btn-${cellParams.row.externalId}`}
              disabled={disableSubmit}
              size="small"
              loading={loading || isValidating || isSubmitting}
              variant="primary"
              onClick={() => handleSubmit()}
            >
              Save
            </CanopyButton>
          </div>
        )
      }}
    </Formik>
  )
}
