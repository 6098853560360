import React, { useState } from "react"
import { ClinicalFacility, Employer } from "sharedTypes"
import { Button, Form, TextField } from "components/form"
import Alert from "components/Alert"
import LearnMore from "../Workflow/containers/Review/components/PatientPortal/LearnMore"
import Popover from "components/Popover"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { build, FacilityNameData } from "services/patientPortal"
import withInitialData from "components/withInitialData"
import { blur } from "utilities/blur"
import { FormikProps } from "formik"

type Props = {
  clinicalFacility: ClinicalFacility
  employer: Employer
  initialData: FacilityNameData
}

const InfoIcon = () => (
  <div className="canopy-mis-1x">
    <CanopyIcon
      name="circle-info"
      size="small"
      fill="canopyColorPrimitivesBlue41"
    />
  </div>
)

const OrderTrackerSettings: React.FC<Props> = ({
  clinicalFacility,
  initialData,
}) => {
  const [initialValues, setInitialValues] = useState(initialData)
  const [fullNamePopoverOpen, setFullNamePopoverOpen] = useState(false)
  const [shortNamePopoverOpen, setShortNamePopoverOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const toggleFullNamePopover = () =>
    setFullNamePopoverOpen((fullNamePopoverOpen) => !fullNamePopoverOpen)

  const toggleShortNamePopover = () =>
    setShortNamePopoverOpen((shortNamePopoverOpen) => !shortNamePopoverOpen)

  const renderSuccessAlert = (
    <Alert leftIcon status="success">
      Order Tracker Settings successfully updated!
    </Alert>
  )

  const renderErrorAlert = (
    <Alert leftIcon status="danger">
      An error occurred, please try again.
    </Alert>
  )

  const validateFullName = (value: string) => {
    if (!value) {
      return "Required"
    }
  }

  const validateShortName = (value: string) => {
    if (!value) {
      return "Required"
    } else if (value.length > 30) {
      return "Must be 30 characters or less"
    }
  }

  const endpoint = build()

  const onSubmit = ({
    facilityFullName,
    facilityShortName,
  }: FacilityNameData) => {
    setShowSuccess(false)
    setShowError(false)

    return endpoint
      .updateFacilityNames(
        clinicalFacility.externalId,
        facilityFullName,
        facilityShortName
      )
      .then(({ data }) => {
        setInitialValues(data)
        setShowSuccess(true)
        blur()
      })
      .catch(() => {
        setShowError(true)
        blur()
      })
  }

  return (
    <>
      {showSuccess && renderSuccessAlert}
      {showError && renderErrorAlert}
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 offset-3">
            <h1>Order Tracker Settings for {clinicalFacility.name}</h1>
            <p>
              The Order Tracker helps improve the patient experience by engaging
              patients at the point of ordering and keeping them in the loop on
              their order.
            </p>
            <p>
              If enabled on the order, the patient will receive an SMS (a text
              message) with an Order Tracker link. After verifying their
              identity, the patient can view their product information, order
              status, and supplier information.
            </p>
            <LearnMore />
            <div className="canopy-mbs-8x">
              <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ errors, isSubmitting }: FormikProps<FacilityNameData>) => (
                  <>
                    <div className="d-flex">
                      <label htmlFor="facilityFullName">
                        Patient-Facing Facility Name
                      </label>
                      <Popover
                        open={fullNamePopoverOpen}
                        onClick={toggleFullNamePopover}
                        onHide={() => setFullNamePopoverOpen(false)}
                        label={<InfoIcon />}
                      >
                        The facility name visible to the patient within Order
                        Tracker
                      </Popover>
                    </div>
                    <TextField
                      name="facilityFullName"
                      validate={validateFullName}
                    />
                    <div className="d-flex">
                      <label htmlFor="facilityShortName">
                        Patient-Facing Facility Short Name (30 characters max)
                      </label>
                      <Popover
                        open={shortNamePopoverOpen}
                        onClick={toggleShortNamePopover}
                        onHide={() => setShortNamePopoverOpen(false)}
                        label={<InfoIcon />}
                      >
                        The facility short name is visible to the patient in the
                        SMS invite
                      </Popover>
                    </div>
                    <TextField
                      name="facilityShortName"
                      validate={validateShortName}
                    />
                    <Button
                      type="submit"
                      className="btn-primary"
                      disabled={
                        !!errors.facilityFullName ||
                        !!errors.facilityShortName ||
                        isSubmitting
                      }
                    >
                      Save
                    </Button>
                  </>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const fetchInitialData = (args: Omit<Props, "initialData">) => {
  return build()
    .fetchFacilityNames(args.clinicalFacility.externalId)
    .then(({ data }) => data)
}
export default withInitialData(fetchInitialData)(OrderTrackerSettings)
