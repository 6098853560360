import React from "react"
import classNames from "classnames"
import Icon from "components/Icon"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Pages from "../Pages"
import { fullName } from "utilities/person"
import { DmeOrder, Page } from "sharedTypes"
import * as styles from "./index.module.scss"

type Props = {
  open?: boolean
  buttons: React.ReactElement[]
  pages: Page[]
  nextPagePath?: string
  dmeOrder: DmeOrder
  patientLink: React.ReactElement
  toggle(): void
}

function BottomNavigation({
  open,
  buttons,
  dmeOrder,
  pages,
  patientLink,
  nextPagePath,
  toggle,
}: Props) {
  const currentIndex = pages.findIndex((page) => page.active)
  const currentPage = pages[currentIndex]
  const hasMultiplePages = pages.length > 1
  const currentPageToggle = () => {
    if (!currentPage) {
      return fullName(dmeOrder.patient)
    }

    return (
      <>
        <Icon
          type={currentPage.checked ? "check-circle" : "circle"}
          className={classNames("canopy-mie-8x font-l float-left", {
            "color-secondary": currentPage.checked,
            "color-mid-gray": !currentPage.checked,
          })}
          style={{ fontWeight: currentPage.checked ? 700 : 500 }}
        />
        <div className={styles.title}>
          {currentIndex >= 0 && hasMultiplePages && (
            <div className={styles.step}>Step {currentIndex + 1}</div>
          )}
          {currentPage.title}
        </div>
      </>
    )
  }

  return (
    <div
      className={classNames({ [styles.overlay]: open })}
      data-testid="nav-overlay"
      data-active={open}
    >
      <div
        className={styles.space}
        onClick={toggle}
        data-testid="nav-overlay-space"
      />
      <div className={styles.mobile}>
        {open && (
          <div className={styles.body}>
            <div className={styles.actions}>
              <div className="row canopy-mbs-2x">
                {buttons.map((button, index) => {
                  return (
                    <div
                      className={classNames(styles.item, "col-6")}
                      key={`button-${button.props.className}-${index}`}
                    >
                      {button}
                    </div>
                  )
                })}
              </div>
            </div>
            {patientLink}
            <Pages pages={pages} removeBottomBorder={false} />
          </div>
        )}
        <div className={styles.bar} onClick={toggle} data-testid="bar">
          <div className={styles.header}>{currentPageToggle()}</div>
          {nextPagePath && (
            <div className={styles.button}>
              <ContinueButton
                isButtonOutlined={!currentPage.checked}
                path={nextPagePath}
              />
            </div>
          )}
          <Icon
            type={open ? "caret-down" : "caret-up"}
            className={styles.icon}
          />
        </div>
      </div>
    </div>
  )
}

export default BottomNavigation
