import React, { useState } from "react"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import PatientPortalMessageTemplates from "./PatientPortalMessageTemplates"
import ChatDisabledHero from "./ChatDisabledHero"
import AutomatedWelcomeMessage from "./AutomatedWelcomeMessage"
import withInitialData from "components/withInitialData"
import { SupplierSettings } from "services/patientPortal"
import * as patientPortal from "services/patientPortal"

interface Props {
  initialData: SupplierSettings
  automatedWelcomeMessage: boolean
  supplierId: string
  supplierName: string
  messageTemplates: {
    id: string
    name: string
    template: string
  }[]
  supportEmail: string
}

enum Tabs {
  MessageTemplates = "messageTemplates",
  AutomatedWelcomeMessage = "automatedWelcomeMessage",
}

const PatientPortalMessages = ({
  automatedWelcomeMessage,
  supplierName,
  messageTemplates,
  supportEmail,
  initialData,
  supplierId,
}: Props) => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.MessageTemplates)

  return (
    <div>
      <div className={styles.container}>
        <ul role="tablist" className={styles.tabList}>
          <li role="presentation" className={styles.tabContainer}>
            <div
              role="tab"
              className={classNames(styles.tab, {
                [styles.tabActive]: activeTab === Tabs.MessageTemplates,
              })}
              aria-selected={activeTab === Tabs.MessageTemplates}
              onClick={() => setActiveTab(Tabs.MessageTemplates)}
            >
              Patient Message Templates {supplierName}
            </div>
          </li>
          {automatedWelcomeMessage && (
            <li role="presentation" className={styles.tabContainer}>
              <div
                role="tab"
                className={classNames(styles.tab, {
                  [styles.tabActive]:
                    activeTab === Tabs.AutomatedWelcomeMessage,
                })}
                aria-selected={activeTab === Tabs.AutomatedWelcomeMessage}
                onClick={() => setActiveTab(Tabs.AutomatedWelcomeMessage)}
              >
                Automated Welcome Message
              </div>
            </li>
          )}
        </ul>
      </div>

      {activeTab === Tabs.MessageTemplates && initialData?.portalMessaging && (
        <PatientPortalMessageTemplates
          supplierName={supplierName}
          messageTemplates={messageTemplates}
        />
      )}

      {activeTab === Tabs.MessageTemplates &&
        initialData?.portalMessaging === false && (
          <ChatDisabledHero
            supplierName={supplierName}
            supportEmail={supportEmail}
          />
        )}

      {automatedWelcomeMessage &&
        activeTab === Tabs.AutomatedWelcomeMessage && (
          <AutomatedWelcomeMessage supplierId={supplierId} />
        )}
    </div>
  )
}

const fetchInitialData = ({
  supplierId,
}: Omit<Props, "initialData">): Promise<SupplierSettings> => {
  const endpoint = patientPortal.build()

  return endpoint.fetchSupplier(supplierId).then(({ data }) => data)
}

export default withInitialData(fetchInitialData)(PatientPortalMessages)
