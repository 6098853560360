import { Form, Button } from "../../../components/form"
import { Field } from "formik"
import TemplateFormField from "../TemplateFormField"
import React, { Dispatch, SetStateAction } from "react"
import { SupplierSettings } from "services/patientPortal"
import * as patientPortal from "services/patientPortal"

const AutomatedWelcomeMessageForm = ({
  action,
  supplierId,
  hideForm,
  setSupplierSettings,
  supplierSettings,
}: {
  action: string
  supplierId: string
  supplierSettings: SupplierSettings
  setSupplierSettings: Dispatch<SetStateAction<SupplierSettings>>
  hideForm: () => void
}) => {
  const endpoint = patientPortal.build()

  const updateSupplierSettings = ({ template }) =>
    endpoint
      .updateSupplier(supplierId, template)
      .then(({ data }) => data)
      .catch(({ response }) => Promise.reject(response.data))

  const onSubmit = (values, { setErrors }) => {
    updateSupplierSettings(values)
      .then((data) => {
        hideForm()
        setSupplierSettings(data)
      })
      .catch(({ errors }) => setErrors(errors))
  }

  return (
    <>
      <h1 className="canopy-my-8x">{action} Automated Welcome Message</h1>
      <div className="canopy-mbe-12x">
        Once added, {supplierSettings.name} patients with Order Tracker access
        will receive this message upon order submission. Patients will verify
        their identity via the Order Tracker before viewing your message.
      </div>
      <Form
        initialValues={{
          name: "Automated Welcome Message",
          template: supplierSettings?.automatedWelcomeMessage || "",
        }}
        onSubmit={onSubmit}
      >
        {({ errors, isSubmitting, values }) => (
          <>
            <Field name="template">
              {({ field, form }) => (
                <TemplateFormField
                  field={field}
                  form={form}
                  values={values}
                  label="Welcome Message"
                />
              )}
            </Field>
            <Button
              type="submit"
              className="btn-primary"
              disabled={!!errors.name || isSubmitting}
            >
              Save
            </Button>
          </>
        )}
      </Form>
    </>
  )
}

export default AutomatedWelcomeMessageForm
