import React, { PropsWithChildren } from "react"
import { canopyColorTextPrimary } from "@parachutehealth/canopy-tokens-color"
import { Grid } from "@material-ui/core"

interface CardSectionContainerProps {
  headerText?: string
  borderLeft?: boolean
}

export const CardSectionContainer: React.FC<PropsWithChildren<
  CardSectionContainerProps
>> = ({ headerText, borderLeft, children }) => {
  return (
    <Grid
      item
      className={`${
        borderLeft && "border-left canopy-pis-8x"
      }  canopy-pie-8x canopy-typography-body-medium canopy-mbe-8x`}
      style={{ color: canopyColorTextPrimary, flex: "1 0 16%" }}
    >
      {headerText && (
        <h4 className="canopy-mbe-0 canopy-typography-font-weight-bold">
          {headerText}
        </h4>
      )}
      {children}
    </Grid>
  )
}
