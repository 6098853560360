// @ts-strict-ignore
import SideBarAwareContainer from "../SideBarAwareContainer"
import React from "react"
import { Props, NestedOptions, Option } from "./sharedTypes"
import { scrollTop } from "utilities/scroll"
import { useFeatureFlags } from "components/FeatureFlagContext"
import classNames from "classnames"
import { allowSearchByProduct } from "../../../../utilities/searchByProduct"

const CategorySelectorDesktop: React.FunctionComponent<Props> = ({
  options,
  selectedValue,
  select,
  usesEnterpriseFeatures,
}) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const userActivationProductFirstSearch = allowSearchByProduct(
    isFeatureEnabled("userActivationProductFirstSearch"),
    isFeatureEnabled("userActivationProductFirstSearchIncludeEnterprise"),
    usesEnterpriseFeatures
  )
  const isSelected = (option: NestedOptions) =>
    option.value === selectedValue ||
    option.suboptions.find((childOption) => childOption.value === selectedValue)
  const renderSuboptions = (suboptions: Option[]) =>
    suboptions.length > 0 && (
      <div className="select-one-suboptions">
        {suboptions.map(renderOption)}
      </div>
    )
  const renderOption = (option: NestedOptions) => (
    <div className="select-one-option" key={option.value}>
      <a
        onClick={() => {
          if (!option.disabled) {
            scrollTop()
            select(option.value)
          }
        }}
        className={classNames({
          selected: selectedValue === option.value,
          disabled: option.disabled,
        })}
      >
        {option.label}
      </a>
      {option.suboptions &&
        isSelected(option) &&
        renderSuboptions(option.suboptions)}
    </div>
  )

  return (
    <SideBarAwareContainer role="selectOne">
      {userActivationProductFirstSearch && (
        <div className="bold canopy-pis-4x canopy-pbe-8x">Categories</div>
      )}
      <div className="select-one">{options.map(renderOption)}</div>
    </SideBarAwareContainer>
  )
}

export default CategorySelectorDesktop
