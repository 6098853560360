// @ts-strict-ignore
import React from "react"
import Icon from "components/Icon"
import { Document } from "../sharedTypes"
import ArchiveButton from "./ArchiveButton"

export type Props = {
  index: number
  documents: Document[]
  archive?(document: Document): void
  previous(): void
  next(): void
  zoomIn(): void
  zoomOut(): void
  rotate?(): void
  onRotateMouseEnter(): void
  onRotateMouseLeave(): void
  onDownload?(): void
}

function Actions({
  archive,
  documents,
  index,
  previous,
  next,
  zoomIn,
  zoomOut,
  rotate,
  onRotateMouseEnter,
  onRotateMouseLeave,
  onDownload,
}: Props) {
  const document = documents[index]
  const canArchive = archive && document && document.permissions.archive
  return (
    <div id="sidebar-actions">
      {documents.length > 0 && (
        <div className="canopy-mbs-8x">
          <div className="float-left">
            <button
              className="btn btn-secondary btn-xs zoom-out"
              onClick={zoomOut}
            >
              <Icon type="minus" />
            </button>
            <button
              className="btn btn-secondary btn-xs zoom-in canopy-mis-4x"
              onClick={zoomIn}
            >
              <Icon type="plus" />
            </button>
            {rotate && (
              <button
                className="btn btn-secondary btn-xs rotate canopy-mis-4x"
                onClick={rotate}
                onMouseEnter={onRotateMouseEnter}
                onMouseLeave={onRotateMouseLeave}
              >
                <Icon type="redo" />
              </button>
            )}
            {canArchive && <ArchiveButton archive={() => archive(document)} />}
          </div>
          <button
            className="btn btn-secondary btn-xs previous canopy-mbe-4x"
            onClick={previous}
          >
            <Icon className="canopy-mie-4x" type="chevron-left" />
            {index + 1}
          </button>
          <small className="canopy-mx-4x canopy-mbe-4x">of</small>
          <button
            className="btn btn-secondary btn-xs next canopy-mbe-4x"
            onClick={next}
          >
            {documents.length}
            <Icon className="canopy-mis-4x" type="chevron-right" />
          </button>
          <a
            className="btn btn-secondary btn-xs canopy-mis-4x download canopy-mbe-4x"
            href={document.downloadUrl}
            onClick={onDownload}
          >
            <Icon type="cloud-download-alt" />
            <span className="d-none d-lg-inline canopy-mis-4x">
              Download this document
            </span>
          </a>
        </div>
      )}
    </div>
  )
}

export default Actions
