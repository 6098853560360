import React from "react"
import { FacilityOption } from "sharedTypes"
import { formatAddress } from "utilities/address"
import { pluralize } from "utilities/string"
import compact from "lodash/compact"
import Suggestion from "components/form/Select/Suggestion"

type Props = {
  facility: FacilityOption
  isHighlighted?: boolean
}

function FacilitySuggestion({ facility, isHighlighted }: Props) {
  const userLine =
    facility.source === "fulfillment_agreement"
      ? `${facility.userCount} ${pluralize("user", facility.userCount)}`
      : "Not on Parachute"
  const facilityId = facility.supplierSystemId
    ? `#${facility.supplierSystemId}`
    : null

  return (
    <Suggestion isHighlighted={isHighlighted}>
      <div className="row">
        <div className="col-md-8">
          <div className="bold">
            {compact([facility.name, facilityId]).join(" - ")}
          </div>
        </div>
        <div className="col-md-4 text-right">{userLine}</div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {formatAddress(facility)}
          {facility.inactive && (
            <p className="font-notice color-dark-gray canopy-mbe-0">
              Inactive fulfillment agreement
            </p>
          )}
        </div>
      </div>
    </Suggestion>
  )
}

FacilitySuggestion.defaultProps = {
  isHighlighted: false,
}

export default FacilitySuggestion
