// @team @clinical-intelligence

import { put } from "services/api"
import {
  dmeOrderQuestionAnswerUrl,
  startQuestionnaireResponseUrl,
} from "applications/Workflow/urls"
import {
  SurveyQuestion,
  SurveyAnswerValue,
  SurveyAnswerType,
} from "sharedTypes"

export function answerQuestion(
  dmeOrderFaceToFaceId: string,
  question: SurveyQuestion,
  answerType: SurveyAnswerType,
  answerValue: SurveyAnswerValue
) {
  // TODO(WF-24999): make these use the questionnaire response version
  return put(dmeOrderQuestionAnswerUrl(question.questionId), {
    answerType,
    answerValue,
    dmeOrderFaceToFaceId,
    surveyId: question.surveyId,
  })
}

export const startQuestionnaireResponse = (questionnaireResponseId: string) => {
  return put(startQuestionnaireResponseUrl(questionnaireResponseId)).then(
    ({ data }) => data
  )
}
