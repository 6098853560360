import React from "react"
import { formatHeight } from "utilities/conversion"
import { formatPaymentMethod } from "utilities/payment"
import QuantityForm from "../QuantityForm"
import { PackageConfiguration } from "../../sharedTypes"
import { OfferingType, SearchWorkflow } from "sharedTypes"
import { FormikValues } from "formik"
import classNames from "classnames"
import { trackClick } from "../../../Navigation/components/TopNavigation/analytics"
import Confirm from "components/Confirm"
import FormularyUnitPrice from "../../../Sidebar/containers/Cart/components/CartLineItemGroup/FormularyUnitPrice"
import { formularyUnitPrice } from "../../../../utilities/formularyPrice"
import FormularySubtotal from "../../../Sidebar/containers/Cart/components/CartLineItemGroup/FormularySubtotal"
import ProductSummarySection from "./ProductSummarySection"

type Props = {
  packageConfiguration: PackageConfiguration
  formularyPriceEnabled: boolean
  explicitSupplierSelectionStepFeatureEnabled: boolean
  onEditSupplier(): void
  onEditDetails(): void
  onEditRequirements(): void
  onEditRxDetails(): void
  onEditConfiguration(): void
  remove(): void
  update(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  returnToBrowse(): void
  useFeetForHeight: boolean
  goToNextPage(values: FormikValues): void
}

const ConfigurationSectionTitles = {
  [OfferingType.Service]: "Services",
  [OfferingType.O2Recert]: "Related Offerings",
  [OfferingType.Equipment]: "Configuration",
}

function ProductSummary(props: Props): React.ReactElement {
  const {
    packageConfiguration,
    formularyPriceEnabled,
    explicitSupplierSelectionStepFeatureEnabled,
    onEditSupplier,
    onEditDetails,
    onEditRequirements,
    onEditRxDetails,
    onEditConfiguration,
    returnToBrowse,
    remove,
    update,
    useFeetForHeight,
    goToNextPage,
  } = props
  const {
    dmeOrderLineItemGroup,
    requiresHeight,
    requiresWeight,
    requiresWoundCount,
    supportedPaymentMethods,
    heightInches,
    weightLbs,
    numberOfWounds,
    rxDetails,
    warnings,
  } = packageConfiguration

  const isService = packageConfiguration.offeringType === OfferingType.Service
  const isO2Recert = packageConfiguration.offeringType === OfferingType.O2Recert

  const configurationSectionTitle =
    ConfigurationSectionTitles[packageConfiguration.offeringType] ||
    "Configuration"

  const headerSection = () => {
    const packageNameHeader = (
      <>
        <h2 className="canopy-mbs-0">{packageConfiguration.packageName}</h2>
        {formularyPriceEnabled ? (
          <FormularyUnitPrice
            unitPrice={formularyUnitPrice(dmeOrderLineItemGroup)}
          />
        ) : null}
      </>
    )

    if (!packageConfiguration.simplePackage) {
      return <div className="canopy-mbe-24x">{packageNameHeader}</div>
    }

    return (
      <div className="row canopy-mbe-24x">
        <div className="col-md-10">{packageNameHeader}</div>
        <div className="col-md-2">
          {" "}
          <QuantityForm
            packageConfiguration={packageConfiguration}
            onSubmit={update}
          />
          <div className="mt-n2">
            {formularyPriceEnabled ? (
              <FormularySubtotal
                unitPrice={formularyUnitPrice(dmeOrderLineItemGroup)}
                quantity={dmeOrderLineItemGroup.quantity}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  const supplierSection = (
    <ProductSummarySection
      header="Supplier"
      onEditButtonClick={() =>
        explicitSupplierSelectionStepFeatureEnabled &&
        packageConfiguration.searchWorkflow === SearchWorkflow.SearchByProduct
          ? onEditSupplier()
          : onEditConfiguration()
      }
      showEditButton={packageConfiguration.availableSuppliers.length > 1}
    >
      {packageConfiguration?.supplier?.name}
    </ProductSummarySection>
  )

  const configurationSection = (
    <ProductSummarySection
      header={configurationSectionTitle}
      onEditButtonClick={onEditConfiguration}
    >
      {dmeOrderLineItemGroup.lineItems.map((lineItem) => (
        <li key={lineItem.skuDescription}>
          <span className="font-xs canopy-mbe-0">
            <strong>{lineItem.skuDescription}</strong>{" "}
            {lineItem.hcpcs && `- ${lineItem.hcpcs}`}
          </span>
        </li>
      ))}
    </ProductSummarySection>
  )

  const requirementsSection = (
    <ProductSummarySection
      header="Requirements"
      onEditButtonClick={onEditRequirements}
    >
      <ul className="list-unstyled canopy-mbe-0">
        {requiresHeight && (
          <li>
            Patient height: {formatHeight(useFeetForHeight, heightInches)}
          </li>
        )}
        {requiresWeight && <li>Patient weight: {weightLbs} lbs</li>}
        {requiresWoundCount && <li>Wound count: {numberOfWounds}</li>}
      </ul>
    </ProductSummarySection>
  )

  const rxDetailsSection = isO2Recert ? (
    <div
      className={classNames("canopy-mbs-12x canopy-mis-4x", {
        "canopy-mbe-12x": !!warnings.length,
      })}
    >
      <a className="link" onClick={onEditRxDetails}>
        View/Edit Document Details
      </a>
    </div>
  ) : (
    <ProductSummarySection
      header={isService ? "Service Details" : "Prescription Details"}
      onEditButtonClick={onEditRxDetails}
      showEditButton={rxDetails.hasQuestions}
    >
      {rxDetails.outputs.map((lineItemDetails) => (
        <li key={lineItemDetails.label}>
          {lineItemDetails.label}: {lineItemDetails.value}
        </li>
      ))}
    </ProductSummarySection>
  )

  const detailsSection = () => {
    if (isO2Recert) {
      return
    }
    const detailedItems = [
      packageConfiguration.requiresLengthOfNeed && (
        <li key="length-of-need">
          Length of need: {packageConfiguration.lengthOfNeedMonths}&nbsp;mo
        </li>
      ),
      !packageConfiguration.simplePackage && (
        <li key="quantity">Quantity: {packageConfiguration.quantity}</li>
      ),
      supportedPaymentMethods.length > 1 && (
        <li key="payment-method">
          Payment Method:{" "}
          {formatPaymentMethod(packageConfiguration.paymentMethod)}
        </li>
      ),
    ].filter((li) => li)
    if (detailedItems.length === 0) {
      return
    }

    return (
      <ProductSummarySection header="Details" onEditButtonClick={onEditDetails}>
        <ul className="list-unstyled canopy-mbe-0">
          {detailedItems.map((li) => li)}
        </ul>
      </ProductSummarySection>
    )
  }

  const warningsSection = (
    <>
      <div className="well well-warning well-expand canopy-mbe-12x">
        Attention:
        {warnings.map((warning) => (
          <span key={warning}> {warning}</span>
        ))}
      </div>
    </>
  )

  const actionsSection = (
    <div className="canopy-mbs-8x">
      <div className="d-flex flex-column-reverse flex-md-row justify-content-between">
        <Confirm
          title={`Remove ${packageConfiguration.packageName} from order?`}
          cancelText="No, don't remove"
          confirmText="Yes, remove"
          render={(confirm) => (
            <button
              type="button"
              className="btn btn-link color-danger canopy-mbe-4x"
              onClick={confirm(remove)}
            >
              Remove
            </button>
          )}
        />
        <div className="d-flex flex-column-reverse flex-md-row">
          <button
            type="button"
            className="btn btn-brand-o canopy-mbe-4x canopy-mis-4x"
            onClick={returnToBrowse}
          >
            Add Items
          </button>
          <button
            className="btn btn-brand canopy-mbe-4x canopy-mis-4x"
            onClick={(e) => {
              trackClick("continue-button")
              goToNextPage(e)
            }}
          >
            Continue to Addresses & Contacts
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {headerSection()}
      {packageConfiguration?.supplier?.name && supplierSection}
      {configurationSection}
      {(requiresHeight || requiresWeight || requiresWoundCount) &&
        requirementsSection}
      {(rxDetails.hasQuestions || rxDetails.outputs.length > 0) &&
        rxDetailsSection}
      {detailsSection()}
      {!!warnings.length && warningsSection}
      {actionsSection}
    </>
  )
}

export default ProductSummary
