import React from "react"
import Sidebar, { Option } from "components/LeftSidebar"
import { Dashboard } from "@material-ui/icons"
import {
  doctorReviewerDashboardUrl,
  doctorReviewerFacilitySignUpPromoUrl,
  facilityDashboardReviewerSignUpUrl,
  facilityUrl,
  incomingOrdersUrl,
  reviewerSignatureRequestPreferencesUrl,
} from "./urls"
import EditDocumentIcon from "./EditDocumentIcon"
import InboxFullIcon from "./InboxFullIcon"
import Logo from "../Logo"
import * as styles from "./LeftSidebar.module.scss"
import { SidebarListDoctor, SidebarListFacility } from "./sharedTypes"
import { History } from "history"
import { Tab } from "applications/DoctorReviewerDashboard/sharedTypes"
import { EventCategory, trackEvent } from "utilities/tracking"
import { isMobile } from "react-device-detect"
import { navigate } from "utilities/navigation"
import ReviewerFeedbackModalButton from "applications/DoctorReviewerDashboard/components/ReviewerFeedbackModalButton/ReviewerFeedbackModalButton"

enum Options {
  Orders = "Orders",
  SignatureRequests = "Signature Requests",
  PendingSignatureRequests = "Pending Signature Requests",
  IncomingOrders = "Incoming Orders",
  ActionRequired = "Action Required",
}

interface Props {
  active?: Options
  sidebarInformation: {
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    currentDoctorId?: string
    facilities?: SidebarListFacility[]
    currentUserEligibleForFacilitySignup?: boolean
    lastAccessedDoctorEmployerId?: string
    hasSignOnScreenFacilityEmployment?: boolean
    hasIncomingOrders?: boolean
    incomingOrdersEnabled: boolean
  }
  showFacilityDashboardNewPill?: boolean
  showSignatureRequestsDashboardNewPill?: boolean
  featureFlags: {
    incomingOrdersEnabledSetting: boolean
    leftSidebarSignatureRequestsSignup: boolean
    facilityIncomingOrders: boolean
    newPillIncomingOrders: boolean
    facilityIncomingOrdersToActionRequired: boolean
  }
  history?: History
  activeTab?: Tab
  onToggleExpand?: () => void
  expandLeftSidebar?: boolean
}

function LeftSidebar({
  active,
  sidebarInformation,
  showFacilityDashboardNewPill,
  showSignatureRequestsDashboardNewPill,
  featureFlags,
  history,
  activeTab,
  onToggleExpand,
  expandLeftSidebar,
}: Props) {
  const renderOrdersExpanded = active === Options.Orders
  const renderSignatureRequestsExpanded = active === Options.SignatureRequests

  const trackOptionInteraction = (option: string) => {
    trackEvent(EventCategory.ClinicalDashboardSidebar, `${option}_sidebar`)
  }

  const hasActionableSignatureRequests = sidebarInformation.doctors?.some(
    (doctor) => doctor.hasActionableSignatureRequests
  )

  const newPill = (label: string) => ({
    icon: <Logo className={styles.pillIcon} />,
    text: "New",
    className: "pill-shadow pill-info",
    ariaLabel: label,
  })

  const options = () => {
    const options: Option[] = []

    if (sidebarInformation.lastAccessedFacilityEmployerId) {
      const incomingOrdersOption: Option = {
        icon: InboxFullIcon,
        text: featureFlags.facilityIncomingOrdersToActionRequired
          ? Options.ActionRequired
          : Options.IncomingOrders,
        href: incomingOrdersUrl,
        onClick: () => trackOptionInteraction("incoming_orders"),
        active: active === Options.IncomingOrders,
        badgeEnabled: sidebarInformation.hasIncomingOrders,
        pill: featureFlags.newPillIncomingOrders
          ? newPill("Incoming Orders New Pill")
          : undefined,
      }
      const isIncomingOrdersEnabledViaSetting =
        featureFlags.incomingOrdersEnabledSetting &&
        sidebarInformation.incomingOrdersEnabled
      const isFacilityIncomingOrders =
        !featureFlags.incomingOrdersEnabledSetting &&
        featureFlags.facilityIncomingOrders
      const isIncomingOrdersActiveSidebarTab = active === Options.IncomingOrders

      if (
        isIncomingOrdersEnabledViaSetting ||
        isFacilityIncomingOrders ||
        isIncomingOrdersActiveSidebarTab
      ) {
        options.splice(0, 0, incomingOrdersOption)
      }

      const facilityDashboardOption: Option = {
        icon: Dashboard,
        text: Options.Orders,
        href: facilityUrl(sidebarInformation.lastAccessedFacilityEmployerId),
        onClick: () => trackOptionInteraction("orders"),
        active: active === Options.Orders,
        subOptionsExpanded: renderOrdersExpanded,
      }
      const { facilities } = sidebarInformation
      facilityDashboardOption.subOptions =
        facilities &&
        facilities.map((facility) => ({
          active:
            active === Options.Orders &&
            sidebarInformation.lastAccessedFacilityEmployerId === facility.id,
          href: facilityUrl(facility.id),
          text: facility.name,
          onClick: () => {
            trackOptionInteraction("orders")
          },
        }))

      options.splice(1, 0, facilityDashboardOption)
    } else if (showFacilityDashboardNewPill) {
      const facilityDashboardOption: Option = {
        icon: Dashboard,
        text: Options.Orders,
        href: doctorReviewerFacilitySignUpPromoUrl(
          sidebarInformation.currentDoctorId
        ),
        onClick: () => trackOptionInteraction("orders"),
        active: active === Options.Orders,
        pill: newPill("Facility Dashboard New Pill"),
      }
      options.splice(1, 0, facilityDashboardOption)
    }

    if (sidebarInformation.isReviewer) {
      const reviewerDashboardOption: Option = {
        icon: EditDocumentIcon,
        text: Options.SignatureRequests,
        href: doctorReviewerDashboardUrl(
          activeTab as Tab,
          sidebarInformation.lastAccessedDoctorEmployerId
        ),
        onClick: () => trackOptionInteraction("signature"),
        active: active === Options.SignatureRequests,
        badgeEnabled: hasActionableSignatureRequests,
        subOptionsExpanded: renderSignatureRequestsExpanded,
      }

      const { doctors } = sidebarInformation

      reviewerDashboardOption.subOptions =
        doctors &&
        doctors.map((doctor) => ({
          text: doctor.name,
          href: doctorReviewerDashboardUrl(activeTab as Tab, doctor.id),
          active: sidebarInformation.currentDoctorId === doctor.id,
          badgeEnabled: doctor.hasActionableSignatureRequests,
          onClick: (e) => {
            e?.preventDefault()
            trackOptionInteraction("signature")
            if (history) {
              history.push(
                doctorReviewerDashboardUrl(activeTab as Tab, doctor.id)
              )
            } else {
              navigate(doctorReviewerDashboardUrl(activeTab as Tab, doctor.id))
            }
          },
        }))

      if (sidebarInformation.hasSignOnScreenFacilityEmployment) {
        reviewerDashboardOption.subOptionCta = {
          text: "+ Add Clinician",
          href: reviewerSignatureRequestPreferencesUrl(
            sidebarInformation.lastAccessedDoctorEmployerId
          ),
          onClick: () => {
            trackEvent(
              EventCategory.ReviewerSidebarSelfSignUp,
              "add_more_clinicians"
            )
          },
        }
      }

      options.splice(2, 0, reviewerDashboardOption)
    } else if (!isMobile && showSignatureRequestsDashboardNewPill) {
      const reviewerDashboardOption: Option = {
        icon: EditDocumentIcon,
        text: featureFlags.leftSidebarSignatureRequestsSignup
          ? Options.PendingSignatureRequests
          : Options.SignatureRequests,
        href: facilityDashboardReviewerSignUpUrl(
          sidebarInformation.lastAccessedFacilityEmployerId
        ),
        onClick: () => {
          trackOptionInteraction("signature")
        },
        active: active === Options.SignatureRequests,
        multilineText: featureFlags.leftSidebarSignatureRequestsSignup,
      }

      options.splice(2, 0, reviewerDashboardOption)
    }

    return options
  }

  const showFeedbackButton = active === Options.SignatureRequests

  const feedbackButton = showFeedbackButton ? (
    <ReviewerFeedbackModalButton />
  ) : null

  return (
    <Sidebar
      options={options()}
      renderExpanded={expandLeftSidebar || renderSignatureRequestsExpanded}
      fixed
      onToggleExpand={onToggleExpand}
      feedbackButton={feedbackButton}
    />
  )
}

LeftSidebar.Options = Options

export default LeftSidebar
