// @ts-strict-ignore
import React from "react"
import { ChartNote, ChartNoteStatus } from "sharedTypes"
import { Link } from "react-router-dom"
import * as routes from "applications/Workflow/routes"
import { slugify } from "utilities/string"
import { EventCategory, trackEvent } from "utilities/tracking"
import { getTrackingStatus } from "../../DocumentationRequirements/GenerateButton/utilities"

type Props = {
  chartNotes: ChartNote[]
}
const EVENT_PREFIX = "documentation-requirement-build"

const GenerateOptumButton = ({ chartNotes }: Props) => {
  const firstNonQualified = chartNotes.find(
    (cn) => cn.status !== ChartNoteStatus.Qualified
  )

  if (!firstNonQualified) {
    return <></>
  }

  const trackEventSuffix = getTrackingStatus(
    chartNotes.map((chartNote) => chartNote.status)
  )

  const clickTrackEvent = () => {
    trackEventSuffix &&
      trackEvent(
        EventCategory.Activation,
        `${EVENT_PREFIX}-${trackEventSuffix}`
      )
  }

  return (
    <Link
      className="btn btn-block btn-brand"
      onClick={clickTrackEvent}
      to={routes.chartNotePath(
        slugify(firstNonQualified.name),
        firstNonQualified.id
      )}
    >
      Begin Medical Review
    </Link>
  )
}

export default GenerateOptumButton
