import React, { useContext, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { isNullOrUndefined } from "../../../../../../utilities/isNullOrUndefined"
import { Backdrop, CircularProgress } from "@material-ui/core"
import {
  destroyConsignmentClosetSupplierPackageConfiguration,
  getConsignmentCloset,
} from "../../../../api/consignmentClosets"
import {
  CatalogConsignmentCloset,
  CatalogSupplierPackageConfiguration,
  Category,
} from "../../../../types/sharedTypes"
import { isTest } from "../../../../../../utilities/environment"
import DataGridToolbar from "../../../../components/DataGridToolbar"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import ContentArea from "../../../../components/ContentArea"
import DropdownMenu from "../../../../components/DropdownMenu"
import renderCategories from "../../../../components/DataGrid/cells/renderCategories"
import classNames from "classnames"
import * as styles from "../ConsignmentClosetDetailsPage/index.module.scss"
import { CanopyComboboxField } from "@parachutehealth/canopy-combobox-field"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  consignmentClosetsUrl,
  consignmentClosetExportlUrl,
} from "../../../../urls/consignmentClosets"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import NoRows from "../../../../components/DataGrid/NoRows"
import { NoticeContext } from "../../../../contexts/NoticeContext"
import ConfirmDialog from "../../../../components/ConfirmDialog"

type InternalConsignmentClosetDetailPageProps = {
  consignmentCloset: CatalogConsignmentCloset
  onPackageConfigurationRemove: (
    consignmentClosetId: string | number
  ) => Promise<void>
}
const InternalConsignmentClosetDetailPage: React.FC<InternalConsignmentClosetDetailPageProps> = (
  props: InternalConsignmentClosetDetailPageProps
): React.JSX.Element => {
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = React.useState(
    false
  )

  const { consignmentCloset } = props

  const [
    selectedPackageConfiguration,
    setSelectedPackageConfiguration,
  ] = React.useState<CatalogSupplierPackageConfiguration | null>()

  const generatedColumns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: "packageName",
        flex: 1,
        headerName: "Package Name",
      },
      {
        field: "name",
        flex: 1,
        headerName: "Package Configuration Name",
        renderCell: (params) => {
          return (
            <>
              <a href={params.row?.url}>{params?.value?.toString()}</a>
            </>
          )
        },
      },
      {
        field: "categories",
        flex: 1,
        headerName: "Categories",
        minWidth: 200,
        renderCell: renderCategories,
        valueGetter: (params) =>
          (params?.value as Category[])
            ?.map((category) => category.name)
            .join(),
      },
      {
        field: "actions",
        flex: 1,
        headerName: "Actions",
        minWidth: 200,
        renderCell: (params) => {
          return (
            <a
              role="button"
              className="color-danger"
              onClick={() => {
                setSelectedPackageConfiguration(
                  params.row as CatalogSupplierPackageConfiguration
                )
                setRemoveConfirmationOpen(true)
              }}
            >
              Remove
            </a>
          )
        },
        sortable: false,
        filterable: false,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { showNotice } = useContext(NoticeContext)

  const removePackageConfiguration = async () => {
    const success: boolean = await destroyConsignmentClosetSupplierPackageConfiguration(
      consignmentCloset.id,
      selectedPackageConfiguration!.id
    )
    if (success) {
      await props.onPackageConfigurationRemove(consignmentCloset.id)
      showNotice("The package configuration has been removed", "success")
    } else {
      showNotice(
        "An error occurred while removing this package configuration",
        "danger"
      )
    }
  }

  const closeRemovalConfirmation = (): void => {
    setRemoveConfirmationOpen(false)
    setSelectedPackageConfiguration(null)
  }

  return (
    <>
      <div className={classNames(styles.header)}>
        <Link to={consignmentClosetsUrl()} className={classNames(styles.link)}>
          <CanopyIcon
            className={classNames(styles.backBtn)}
            name="angle-left"
            size="small"
            data-testid="back-btn"
          />
          <div className={classNames(styles.allClosets)}>All Closets</div>
        </Link>
        <h1 className="canopy-typography-heading-2xlarge canopy-mt-4x canopy-mb-4x">
          {consignmentCloset.name}
        </h1>
        <div>
          <h4 className={classNames(styles.subheading, "canopy-mb-1x")}>
            Facility
          </h4>
          <span className="canopy-typography-body-small canopy-typography-font-weight-bold">
            {consignmentCloset.clinicalFacilityName}
          </span>
        </div>
        <div>
          <h4 className={classNames(styles.subheading)}>Status</h4>
          <span className="canopy-typography-body-small canopy-typography-font-weight-bold">
            {consignmentCloset.active ? "Active" : "Not Active"}
          </span>
        </div>
      </div>
      <ContentArea>
        <div className={classNames(styles.actions)}>
          <DropdownMenu
            className="canopy-ml-4x"
            label="More Actions"
            id="actions-menu"
          >
            {[
              {
                label: "Export",
                href: consignmentClosetExportlUrl(consignmentCloset.externalId),
              },
              {
                label: "Duplicate Closet",
              },
            ]}
          </DropdownMenu>
          <CanopyComboboxField
            className={classNames(styles.packageSelect)}
            label="Package"
            hiddenLabel={true}
            placeholder="Select Package"
            options={[]}
            size="small"
          />
          <CanopyButton className="canopy-ml-4x" variant="primary" size="small">
            Add Package
          </CanopyButton>
        </div>
        <DataGridPro
          className="borderless"
          rows={consignmentCloset?.supplierPackageConfigurations || []}
          autoHeight
          density="standard"
          columns={generatedColumns}
          pagination={true}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.externalId}
          disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
          hideFooterSelectedRowCount
          components={{
            Toolbar: DataGridToolbar,
            NoRowsOverlay: NoRows,
          }}
          componentsProps={{
            toolbar: { filter: true },
            noRowsOverlay: {
              message:
                "There are currently no package configurations in this Consignment Closet",
            },
          }}
        />
        <ConfirmDialog
          title="Remove Package Configuration"
          message="Removing this Package Configuration will make it unavailable to order from this Facility's Closet."
          confirmButtonText="Confirm"
          cancelButtonText="Cancel"
          open={removeConfirmationOpen}
          onConfirm={() =>
            selectedPackageConfiguration && removePackageConfiguration()
          }
          onCancel={closeRemovalConfirmation}
          handleClose={closeRemovalConfirmation}
        />
      </ContentArea>
    </>
  )
}

const ConsignmentClosetDetailPage: React.FC = () => {
  const { consignmentClosetId } = useParams()

  const [
    catalogConsignmentCloset,
    setCatalogConsignmentCloset,
  ] = React.useState<CatalogConsignmentCloset | undefined>()

  const [loading, setLoading] = React.useState<boolean>(
    isNullOrUndefined(catalogConsignmentCloset)
  )

  const loadConsignmentCloset = async (
    consignmentClosetId: string | number
  ) => {
    await getConsignmentCloset(consignmentClosetId).then(
      (consignmentCloset) => {
        setCatalogConsignmentCloset({ ...consignmentCloset })
      }
    )
    setLoading(false)
  }

  useEffect(() => {
    void loadConsignmentCloset(consignmentClosetId)
  }, [consignmentClosetId])

  if (loading) {
    return (
      <Backdrop style={{ zIndex: 999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  } else {
    return (
      <InternalConsignmentClosetDetailPage
        consignmentCloset={catalogConsignmentCloset!}
        onPackageConfigurationRemove={loadConsignmentCloset}
      />
    )
  }
}

export default ConsignmentClosetDetailPage
