import React, { useCallback, useEffect, useState } from "react"
import { ClinicalFacility, Doctor, Follower } from "sharedTypes"
import { Filters as FiltersType } from "../../sharedTypes"
import { Supplier } from "components/ClinicalFilters/sharedTypes"
import { useDebounce } from "hooks/useDebounce"
import Filters from "../Filters"
import DashboardHeader from "../DashboardHeader"
import DashboardColumn from "../DashboardColumn"
import Icon from "components/Icon"
import StartFirstOrderCard from "../StartFirstOrderCard"
import { navigate } from "utilities/navigation"
import { EventCategory, trackEvent } from "utilities/tracking"
import { newOrderUrl } from "../../urls"
import WelcomeModal from "../WelcomeModal"
import LeftSidebar from "components/ClinicalDashboard/LeftSidebar"
import RemovedLastReviewerEmploymentAlert from "../Alerts/RemovedLastReviewerEmploymentAlert"
import { useFeatureFlags } from "components/FeatureFlagContext"
import {
  SidebarListDoctor,
  SidebarListFacility,
} from "components/ClinicalDashboard/sharedTypes"
import { useEffectThatWontRunOnMount } from "hooks/useEffectThatWontRunOnMount"
import { AxiosPromise } from "axios"
import AdditionalFaxModal from "../AdditionalFaxModal/AdditionalFaxModal"

type Props = {
  clinicalFacility: ClinicalFacility
  limit: number
  unreadEventCounts: object
  getClinicalFacilityDashboardResults(values?): Promise<any>
  followers: Follower[]
  suppliers: Supplier[]
  doctors: { doctorId: string; firstName: string; lastName: string }[]
  searchFollowers(term: string): Promise<Follower[]>
  searchSuppliers(term: string): Promise<Supplier[]>
  searchDoctors(term: string): Promise<Doctor[]>
  initialValues: FiltersType
  showStartFirstOrderCard?: boolean
  showWelcomeModal: boolean
  showAdditionalFaxPrompt: boolean
  alerts: {
    removedLastReviewerEmploymentDoctor?: string
  }
  sidebarInformation: {
    incomingOrdersEnabled: boolean
    isReviewer: boolean
    lastAccessedFacilityEmployerId?: string
    doctors?: SidebarListDoctor[]
    facilities?: SidebarListFacility[]
    lastAccessedDoctorEmployerId?: string
  }
  sidebarExpanded: boolean
  updateSidebarExpandedPreference: (sidebarExpanded: boolean) => AxiosPromise
  currentUserEligibleForReviewerSignUp?: boolean
}

const InternalClinicalFacilityDashboard: React.FC<Props> = ({
  clinicalFacility,
  limit,
  followers,
  suppliers,
  doctors,
  getClinicalFacilityDashboardResults,
  searchFollowers,
  searchSuppliers,
  searchDoctors,
  initialValues,
  showStartFirstOrderCard,
  showWelcomeModal,
  showAdditionalFaxPrompt,
  alerts,
  sidebarInformation,
  sidebarExpanded,
  updateSidebarExpandedPreference,
  currentUserEligibleForReviewerSignUp,
}: Props) => {
  const [loading, setLoading] = useState(true)
  const [inProgressOrders, setInProgressOrders] = useState([])
  const [submittedOrders, setSubmittedOrders] = useState([])
  const [doneOrders, setDoneOrders] = useState([])
  const [unreadEventCounts, setUnreadEventCounts] = useState({})
  const [initialOriginator, setInitialOriginator] = useState<string>(() =>
    initialValues.originator ? initialValues.originator : ""
  )
  const [initialFollowerIds, setInitialFollowerIds] = useState<string[]>(
    followers.map((follower) => follower.id)
  )
  const [initialSupplierIds, setInitialSupplierIds] = useState<string[]>(() =>
    suppliers.map((supplier) => supplier.externalId)
  )
  const [initialDeliveryDateStart, setInitialDeliveryDateStart] = useState(
    initialValues.deliveryDateStart
  )
  const [initialDeliveryDateEnd, setInitialDeliveryDateEnd] = useState(
    initialValues.deliveryDateEnd
  )
  const [initialSort, setInitialSort] = useState<string>(() =>
    initialValues.sort ? initialValues.sort : ""
  )
  const [initialQuery, setInitialQuery] = useState("")
  const [initialDoctorIds, setInitialDoctorIds] = useState<string[]>(() =>
    doctors.map((doctor) => doctor.doctorId)
  )
  const { isFeatureEnabled } = useFeatureFlags()
  const incomingOrdersEnabledSetting = isFeatureEnabled(
    "incomingOrdersEnabledSetting"
  )
  const leftSidebarSignatureRequestsSignup = isFeatureEnabled(
    "leftSidebarSignatureRequestsSignup"
  )
  const facilityIncomingOrders = isFeatureEnabled("facilityIncomingOrders")

  const facilityIncomingOrdersToActionRequired = isFeatureEnabled(
    "facilityIncomingOrdersToActionRequired"
  )

  const newPillIncomingOrders = isFeatureEnabled("newPillIncomingOrders")

  const [initialLeftSidebarExpanded, setInitialLeftSidebarExpanded] = useState<
    boolean
  >(sidebarExpanded)

  const debouncedGetClinicalFacilityDashboardResults = useDebounce(
    getClinicalFacilityDashboardResults
  )
  const fetch = useCallback(
    (values) =>
      debouncedGetClinicalFacilityDashboardResults(values)
        .then((results) => {
          setInProgressOrders(results.inProgressOrders)
          setSubmittedOrders(results.submittedOrders)
          setDoneOrders(results.doneOrders)
          setUnreadEventCounts(results.unreadEventCounts)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        }),
    [debouncedGetClinicalFacilityDashboardResults]
  )

  useEffect(() => {
    fetch(initialValues)
  }, [fetch, initialValues])

  useEffect(() => {
    setLoading(true)
    fetch({
      query: initialQuery,
      originator: initialOriginator,
      followers: initialFollowerIds,
      supplier_ids: initialSupplierIds,
      doctor_ids: initialDoctorIds,
      deliveryDateStart: initialDeliveryDateStart,
      deliveryDateEnd: initialDeliveryDateEnd,
      sort: initialSort,
    })
    return () => setLoading(false)
  }, [
    fetch,
    initialQuery,
    initialOriginator,
    initialFollowerIds,
    initialSupplierIds,
    initialDoctorIds,
    initialDeliveryDateStart,
    initialDeliveryDateEnd,
    initialSort,
  ])

  const EVENT_CATEGORY = EventCategory.Activation
  const EVENT_NAME = "click-activation-start-first-order-button"

  const onStartFirstOrderClick = () => {
    trackEvent(EVENT_CATEGORY, EVENT_NAME).then(() => {
      navigate(newOrderUrl())
    })
  }

  const showSignatureRequestsDashboardNewPill = currentUserEligibleForReviewerSignUp

  function onToggleExpand() {
    setInitialLeftSidebarExpanded(!initialLeftSidebarExpanded)
  }

  useEffectThatWontRunOnMount(() => {
    updateSidebarExpandedPreference(initialLeftSidebarExpanded)
  }, [initialLeftSidebarExpanded])

  return (
    <div className="position-relative">
      <LeftSidebar
        active={LeftSidebar.Options.Orders}
        sidebarInformation={sidebarInformation}
        showSignatureRequestsDashboardNewPill={
          showSignatureRequestsDashboardNewPill
        }
        featureFlags={{
          incomingOrdersEnabledSetting: !!incomingOrdersEnabledSetting,
          leftSidebarSignatureRequestsSignup: !!leftSidebarSignatureRequestsSignup,
          facilityIncomingOrders: !!facilityIncomingOrders,
          facilityIncomingOrdersToActionRequired: !!facilityIncomingOrdersToActionRequired,
          newPillIncomingOrders: !!newPillIncomingOrders,
        }}
        onToggleExpand={onToggleExpand}
        expandLeftSidebar={initialLeftSidebarExpanded}
      />
      <div className="row mt-n3">
        <div className="col-12 canopy-mbs-12x">
          {alerts.removedLastReviewerEmploymentDoctor && (
            <RemovedLastReviewerEmploymentAlert
              doctorName={alerts.removedLastReviewerEmploymentDoctor}
              showSignupLink={currentUserEligibleForReviewerSignUp}
            />
          )}
          <p
            className="canopy-typography-heading-xlarge"
            aria-label="facility name"
          >
            {clinicalFacility.name}
          </p>
          <Filters
            loading={loading}
            initialQuery={initialQuery}
            setInitialQuery={setInitialQuery}
            followers={followers}
            suppliers={suppliers}
            doctors={doctors}
            initialOriginator={initialOriginator}
            setInitialOriginator={setInitialOriginator}
            initialFollowerIds={initialFollowerIds}
            setInitialFollowerIds={setInitialFollowerIds}
            initialDeliveryDateStart={initialDeliveryDateStart}
            setInitialDeliveryDateStart={setInitialDeliveryDateStart}
            initialDeliveryDateEnd={initialDeliveryDateEnd}
            setInitialDeliveryDateEnd={setInitialDeliveryDateEnd}
            initialSupplierIds={initialSupplierIds}
            setInitialSupplierIds={setInitialSupplierIds}
            initialDoctorIds={initialDoctorIds}
            setInitialDoctorIds={setInitialDoctorIds}
            initialSort={initialSort}
            setInitialSort={setInitialSort}
            searchFollowers={searchFollowers}
            searchSuppliers={searchSuppliers}
            searchDoctors={searchDoctors}
            onClear={() => {
              setInitialOriginator("")
              setInitialSupplierIds([])
              setInitialFollowerIds([])
              setInitialDeliveryDateStart("")
              setInitialDeliveryDateEnd("")
              setInitialQuery("")
              setInitialDoctorIds([])
            }}
          />
          {loading ? (
            <div className="text-center">
              <Icon type="spinner" spin />
            </div>
          ) : (
            <div className="js-order-columns">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Orders in Progress"
                    limit={limit}
                    count={inProgressOrders.length}
                  />
                  {showStartFirstOrderCard && (
                    <StartFirstOrderCard onClick={onStartFirstOrderClick} />
                  )}
                  <DashboardColumn
                    dmeOrders={inProgressOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showProgress
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Sent to Supplier"
                    limit={limit}
                    count={submittedOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={submittedOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                    showMissingDocument
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <DashboardHeader
                    title="Accepted by Supplier"
                    limit={limit}
                    count={doneOrders.length}
                  />
                  <DashboardColumn
                    dmeOrders={doneOrders}
                    clinicalFacility={clinicalFacility}
                    unreadEventCounts={unreadEventCounts}
                    limit={limit}
                    showSwatch
                  />
                </div>
              </div>
            </div>
          )}
          {showWelcomeModal && <WelcomeModal />}
          {showAdditionalFaxPrompt && (
            <AdditionalFaxModal facilityName={clinicalFacility.name} />
          )}
        </div>
      </div>
    </div>
  )
}

InternalClinicalFacilityDashboard.defaultProps = {
  initialValues: {},
}

export default InternalClinicalFacilityDashboard
