import React from "react"
import OrderAlerts, { Props as orderAlertsProps } from "./OrderAlerts"
import ActionsTitleSection, {
  Props as ActionTitleProps,
} from "../ActionsTitleSection"
import { Permissions } from "../../sharedTypes"
import ReorderForm from "../ReorderForm"
import ActionButtonWithConfirm from "../ActionButtonWithConfirm"
import {
  DmeOrder,
  DmeOrderWarning,
  Employer,
  SignatureType,
  TitleStatus,
} from "sharedTypes"
import SubmitButton from "../SubmitButton"
import AttestationsSection from "./AttestationsSection"
import SendSignatureReminderButton from "../SendSignatureReminderButton"
import * as styles from "./FacilityActions.module.scss"
import { getTitleStatus } from "../../utilities/titleStatus"
import classNames from "classnames"
import cx from "classnames"
import NotificationsSignUpButton from "./NotificationsSignUpButton"

type Props = {
  dmeOrder: DmeOrder
  currentEmployer: Employer
  permissions: Permissions
  warnings: DmeOrderWarning[]
  actionTitleProps: ActionTitleProps
  orderAlertsProps: orderAlertsProps
  showReorder: boolean
  pullBack: () => void
  showSubmit: boolean
  selfSign: boolean
  canSubmit: boolean
  submit(): Promise<void>
  submissionPending: boolean
  extraAttestationRequested: boolean
  isExtraAttestationChecked: boolean
  setExtraAttestationChecked(boolean): void
  sendSignatureRequest(params: {}): Promise<void>
  archive(): Promise<void>
}

const FacilityActions = (props: Props) => {
  const {
    actionTitleProps,
    dmeOrder,
    warnings,
    orderAlertsProps,
    showReorder,
    permissions,
    pullBack,
    canSubmit,
    showSubmit,
    submit,
    selfSign,
    submissionPending,
    currentEmployer,
    extraAttestationRequested,
    isExtraAttestationChecked,
    setExtraAttestationChecked,
    sendSignatureRequest,
    archive,
  } = props

  const titleStatus = getTitleStatus(
    dmeOrder.orderStatus,
    warnings,
    actionTitleProps.employer,
    dmeOrder.supplier
  )
  const isReadyToSend = titleStatus === TitleStatus.ReadyToSend

  return (
    <div className={cx("wide-fixed-row", "canopy-pbe-8x")}>
      <div className={classNames(isReadyToSend && styles.purpleBorder, "card")}>
        <div className={styles.title}>
          <ActionsTitleSection {...actionTitleProps} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.horizontalDivider}>
            <OrderAlerts {...orderAlertsProps} />
          </div>
          <div>
            <AttestationsSection
              warnings={warnings}
              dmeOrder={dmeOrder}
              selfSign={selfSign}
              currentEmployer={currentEmployer}
              showSubmit={showSubmit}
              extraAttestationRequested={extraAttestationRequested}
              isExtraAttestationChecked={isExtraAttestationChecked}
              setExtraAttestationChecked={setExtraAttestationChecked}
            />
            {showReorder && (
              <div className="well-wide">
                <ReorderForm dmeOrderId={dmeOrder.id} />
              </div>
            )}
            <NotificationsSignUpButton permissions={permissions} />
            {permissions.pullBack && (
              <ActionButtonWithConfirm
                className="btn btn-brand-o btn-block"
                buttonText="Pull Back to Edit"
                cancelText="Never mind"
                confirmText="Yes, pull back"
                confirmDescription="Pull back the order to make changes. The supplier may stop processing the order until it has been resent. Pending signature requests will not be cancelled."
                confirmTitle="Pull Back Order"
                onClick={pullBack}
              />
            )}
            {permissions.archive && (
              <ActionButtonWithConfirm
                className="btn btn-danger btn-block"
                buttonText="Archive Order"
                cancelText="Cancel"
                confirmText="Confirm"
                confirmDescription={
                  <>
                    <p>
                      Archiving this order will remove it from your dashboard,
                      but it can still be found in the patient's profile.
                    </p>
                    <strong>
                      Are you sure you want to archive this order?
                    </strong>
                  </>
                }
                confirmTitle="Confirm Archive Order"
                onClick={archive}
              />
            )}
            {showSubmit && (
              <SubmitButton
                submissionPending={submissionPending}
                currentEmployer={currentEmployer}
                submit={submit}
                disabled={!canSubmit}
                signNow={
                  selfSign || dmeOrder.signatureType === SignatureType.OnScreen
                }
              />
            )}
            {permissions.sendSignatureReminder && (
              <SendSignatureReminderButton
                className="btn btn-brand-o btn-block"
                sendSignatureRequest={sendSignatureRequest}
                disabled={dmeOrder.transferredOrderLocked}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacilityActions
