// @ts-strict-ignore
import React from "react"
import { format } from "utilities/date"
import { tokenize } from "utilities/commentLexer"
import { Mention } from "components/Event/Mention"

function findMentionById(employmentMentions, value) {
  return employmentMentions.find(({ id }) => value === id)
}

const UNKNOWN_RECORD = {
  email: "",
  name: "Unknown",
}

export default function LastComment({ lastComment }) {
  const tokens = tokenize(lastComment.rawContent || lastComment.content)
  return (
    <div className="comment canopy-mbs-4x">
      {lastComment.createdAt && (
        <div className="comment-date">{format(lastComment.createdAt)}</div>
      )}
      {lastComment.actorName && (
        <div className="comment-header">{lastComment.actorName}</div>
      )}
      {tokens.map((node, index) => {
        const key = node.type + index
        if (node.type === "mention") {
          const employmentMention =
            findMentionById(lastComment.employmentMentions, node.value) ||
            UNKNOWN_RECORD
          return (
            <span key={key}>
              <Mention {...employmentMention} disableTooltip />
            </span>
          )
        }
        return <span key={key}>{node.value}</span>
      })}
    </div>
  )
}
