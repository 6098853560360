import React from "react"
import Card from "components/Card"
import { Severity } from "sharedTypes"

type Props = {
  title: string
  expanded?: boolean
  status?: Severity
  summary?: React.ReactNode
  children: React.ReactNode
  dropdown?: boolean
  badge?: ({ open }) => React.ReactNode
  className?: string
}

function ReviewCard({
  title,
  status,
  summary,
  expanded,
  dropdown,
  badge,
  children,
  className,
}: Props) {
  return (
    <Card
      className={className}
      status={status}
      border="top"
      badge={badge}
      dropdown={dropdown}
      expanded={expanded}
      render={({ open, toggle }) => {
        return (
          <>
            <h3 className="pointer" onClick={toggle}>
              {title}
            </h3>
            {!open && summary && <div className="canopy-mbs-8x">{summary}</div>}
            {(open || !dropdown) && <>{children}</>}
          </>
        )
      }}
    />
  )
}

ReviewCard.defaultProps = {
  dropdown: true,
}

export default ReviewCard
