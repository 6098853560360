import React, { useContext } from "react"
import WorkflowPage from "context/WorkflowPage"

interface Props {
  role:
    | "filters"
    | "hybridSearch"
    | "paginationLink"
    | "productGrid"
    | "searchLabel"
    | "selectOne"
    | "selectOneMobile"
}

const SidebarAwareContainer: React.FC<Props> = ({ role, children }) => {
  const { isSidebarOpen } = useContext(WorkflowPage)
  const sidebarOpenClasses = {
    filters: "col-xl-4 filters",
    hybridSearch: "canopy-my-12x mt-xl-0",
    paginationLink: "offset-xl-4 col-xl-8",
    productGrid: "col-xl-8 product-grid",
    searchLabel: "d-block d-xl-none",
    selectOne: "d-none d-xl-block",
    selectOneMobile: "d-block d-xl-none",
  }
  const sidebarClosedClasses = {
    filters: "col-lg-3",
    hybridSearch: "canopy-my-12x mt-lg-0",
    paginationLink: "offset-lg-3 col-lg-9",
    productGrid: "col-lg-9",
    searchLabel: "d-block d-lg-none",
    selectOne: "d-none d-lg-block",
    selectOneMobile: "d-block d-lg-none",
  }
  const className = isSidebarOpen
    ? sidebarOpenClasses[role]
    : sidebarClosedClasses[role]

  return <div className={className}>{children}</div>
}

export default SidebarAwareContainer
