import { employerPrefix } from "utilities/url"

export const patientPortalMessageTemplatesUrl = () =>
  `${employerPrefix()}/patient_portal_message_templates`

export const patientPortalMessageTemplateUrl = (id) =>
  `${employerPrefix()}/patient_portal_message_templates/${id}`

export const patientPortalAutomatedWelcomeMessageUrl = () =>
  `${employerPrefix()}/automated_welcome_message`
