import DeliveryStatus, { DEFAULT_STATUS } from "components/DeliveryStatus"
import React from "react"
import { DmeOrder } from "sharedTypes"

type Props = {
  dmeOrder: DmeOrder
}

const FulfillmentStatuses = ({ dmeOrder }: Props) => {
  const fulfillmentWithStatus = dmeOrder.fulfillments
  const isNumberNeeded = fulfillmentWithStatus.length > 1
  const getFulfillmentTitle = (index) => {
    return isNumberNeeded
      ? `Fulfillment #${index + 1} Status`
      : "Fulfillment Status"
  }

  return (
    <>
      {fulfillmentWithStatus.map((fulfillment, index) => (
        <div key={`fulfillment-status-${index}`}>
          <span className="font-xs canopy-mie-4x">
            {getFulfillmentTitle(index)}:
          </span>
          <DeliveryStatus
            status={fulfillment.deliveryStatus || DEFAULT_STATUS}
          />
        </div>
      ))}
    </>
  )
}

export default FulfillmentStatuses
