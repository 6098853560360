import React from "react"
import { EmployerType, OrderStatus, SignatureStatus } from "sharedTypes"
import classNames from "classnames"
import startCase from "lodash/startCase"
import SeverityIcon from "components/SeverityIcon"
import {
  getSignatureStatusSeverity,
  showSignatureStatus,
} from "utilities/signatureStatus"

type Props = {
  className?: string
  signatureStatus: SignatureStatus
  orderStatus: OrderStatus
  employerType: EmployerType
}

export default function Component({
  signatureStatus,
  orderStatus,
  employerType,
  className,
}: Props) {
  if (!showSignatureStatus(orderStatus, employerType)) {
    return null
  }
  const severity = getSignatureStatusSeverity(signatureStatus)
  return (
    <div
      className={classNames(
        className,
        `pill pill-${severity}`,
        severity === "default" && "pill-basic"
      )}
    >
      {severity !== "default" && (
        <SeverityIcon className="canopy-mie-2x" type={severity} />
      )}
      Signature {startCase(signatureStatus)}
    </div>
  )
}
