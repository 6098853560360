// @team @facilitators
import React, { useState } from "react"
import { Form } from "components/form"
import Overlay from "components/Overlay"
import { PackageConfiguration } from "../sharedTypes"
import { DmeOrder } from "sharedTypes"
import { FormikValues } from "formik"
import SearchByProductSupplierSelector from "./SearchByProductSupplierSelector/SearchByProductSupplierSelector"

type Props = {
  dmeOrder: DmeOrder
  isSupplierIncomplete: boolean
  showServiceTier: boolean
  packageConfiguration: PackageConfiguration
  onContinue(
    params?: FormikValues
  ): Promise<PackageConfiguration> | Promise<void>
  save(params: FormikValues): Promise<PackageConfiguration> | Promise<void>
  redirectToPreferredSupplier: boolean
  yourOrganizationsSuppliersOnly: boolean
}

const SelectSupplier: React.FC<Props> = (props) => {
  let continueClicked = false

  const [loading, setLoading] = useState<boolean>(false)

  const onContinue = () => {
    continueClicked = true
    props.onContinue().then(() => {
      setLoading(false)
    })
  }

  const onSubmit = (values, { setErrors, resetForm }) => {
    const { save } = props

    setLoading(true)
    save({
      supplierId: values.supplierId,
    })
      .then(() => {
        if (continueClicked) {
          props.onContinue().then(() => {
            setLoading(false)
          })
        } else {
          setLoading(false)
          resetForm()
        }
      })
      .catch((errors) => {
        resetForm()
        if (errors.supplierId) {
          setErrors(errors)
        } else {
          setErrors({ base: "Something went wrong!" })
        }
      })
  }

  const {
    packageConfiguration,
    isSupplierIncomplete,
    showServiceTier,
    redirectToPreferredSupplier,
  } = props

  const initialValues = {
    supplierId: packageConfiguration.supplier?.externalId,
  }

  return (
    <Overlay active={loading} showSpinner>
      <div className="row">
        <div className="col-md-12">
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitOnChange
          >
            <SearchByProductSupplierSelector
              packageConfiguration={packageConfiguration}
              redirectToPreferredSupplier={redirectToPreferredSupplier}
              yourOrganizationsSuppliersOnly={
                props.yourOrganizationsSuppliersOnly
              }
              flexibleSupplierAccess={
                props.dmeOrder.clinicalFacility.oneTimeOrderEnabled
              }
              manageFulfillmentAgreements={
                props.dmeOrder.permissions.manageFulfillmentAgreements
              }
              loading={loading}
              setLoading={setLoading}
              showServiceTier={showServiceTier}
            />
            <div className="form-actions text-right">
              {isSupplierIncomplete || (
                <button
                  type="button"
                  onClick={onContinue}
                  className="btn btn-brand float-right"
                >
                  Continue
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Overlay>
  )
}

export default SelectSupplier
