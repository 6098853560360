// @team @destroythefax
import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { canopyColorBrandPrimary } from "@parachutehealth/canopy-tokens-color"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"

type Props = {
  className: string
  unsnoozeDmeOrder: () => Promise<void>
}

const UnsnoozeButton = ({ className, unsnoozeDmeOrder }: Props) => {
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onClick = () => setOpen(true)

  const handleUnsnooze = () => {
    unsnoozeDmeOrder().then(() => {
      onClose()
    })
  }

  return (
    <>
      <CanopyButton
        fullWidth
        type="button"
        onClick={onClick}
        style={{ color: canopyColorBrandPrimary }}
        className={className}
        variant="tertiary"
        ref={activatorElementRef}
      >
        Unsnooze Order
      </CanopyButton>
      <CanopyDialogModal
        activatorElementRef={activatorElementRef}
        onClose={onClose}
        open={open}
        header="Unsnooze Order"
        primaryFooterButton={
          <CanopyButton onClick={handleUnsnooze}>Confirm</CanopyButton>
        }
        secondaryFooterButton={
          <CanopyButton onClick={onClose} variant="secondary">
            Cancel
          </CanopyButton>
        }
        size="large"
      >
        <div className="bold canopy-pbe-4x">
          Are you sure you want to unsnooze this order?
        </div>
        Unsnoozing will bring the order back to Incoming Orders.
      </CanopyDialogModal>
    </>
  )
}

export default UnsnoozeButton
