import React, { useState } from "react"
import * as styles from "./index.module.scss"
import {
  DmeOrder,
  Nullable,
  ConsignmentCloset,
  CatalogBrowseTab,
  ApplicationError,
} from "sharedTypes"
import ConsignmentClosetsFilter from "../ConsignmentClosetsFilter"
import Checkbox from "../Checkbox"
import Switch from "components/input/Switch"
import Link from "components/Link"
import { fulfillmentAgreementsUrl } from "applications/Workflow/urls"
import CategorySelector from "../CategorySelector"
import SidebarAwareContainer from "../SideBarAwareContainer"
import cx from "classnames"
import { NestedOptions } from "../CategorySelector/sharedTypes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { loadSearchSettings } from "../../../SupplierList/api"
import { handleError } from "../../../../../../utilities/error"

interface Props {
  dmeOrder: DmeOrder
  consignmentClosetOnly: boolean
  toggleConsignmentClosetFilter(): void
  defaultConsignmentClosetId: string
  consignmentClosets: ConsignmentCloset[]
  selectDefaultConsignmentCloset(id: string): Promise<void>

  formularyOnly: boolean
  canFilterByFormulary: boolean
  toggleFormularyFilter(): void

  canFilterByYourOrganizationsSuppliers: boolean
  yourOrganizationsSuppliersOnly: boolean
  toggleYourOrganizationsSuppliersFilter(): void

  categoryOptions: NestedOptions[]
  selectedCategory: Nullable<number>
  selectCategory(n: number): void
  currentCatalogBrowseTab: CatalogBrowseTab
  combinedCatalogSearch: boolean
}

const ProductFilters: React.FC<Props> = ({
  dmeOrder,
  consignmentClosetOnly,
  toggleConsignmentClosetFilter,
  defaultConsignmentClosetId,
  consignmentClosets,
  selectDefaultConsignmentCloset,
  formularyOnly,
  canFilterByFormulary,
  toggleFormularyFilter,
  canFilterByYourOrganizationsSuppliers,
  yourOrganizationsSuppliersOnly,
  toggleYourOrganizationsSuppliersFilter,
  categoryOptions,
  selectedCategory,
  selectCategory,
  currentCatalogBrowseTab,
  combinedCatalogSearch,
}: Props) => {
  const { manageFulfillmentAgreements } = dmeOrder.permissions

  const [selectedServiceAreaState, setSelectedServiceAreaState] = useState<
    string
  >("")

  const getDefaultServiceAreaState: () => void = async () => {
    try {
      const response = await loadSearchSettings()
      if (response) {
        setSelectedServiceAreaState(
          `${response.defaultServiceAreaStates[0]} (Default)`
        )
      }
    } catch (error) {
      handleError(error as ApplicationError)
    }
  }
  if (currentCatalogBrowseTab === CatalogBrowseTab.Product) {
    getDefaultServiceAreaState()
  }

  const addDividerPriorToCategoriesSection =
    consignmentClosets.length > 0 || canFilterByFormulary
  const categoryFilterClasses = () => {
    const filterClasses = [styles.categoryFilter, "canopy-px-4x"]
    if (currentCatalogBrowseTab === CatalogBrowseTab.Supplier) {
      if (!combinedCatalogSearch && !canFilterByFormulary) {
        filterClasses.push(styles.searchBySupplier)
      } else {
        if (combinedCatalogSearch) {
          filterClasses.push(styles.combinedSearch)
        }
        if (canFilterByFormulary) {
          filterClasses.push(styles.consignmentAndFormularyFilter)
        }
      }
    } else if (addDividerPriorToCategoriesSection) {
      filterClasses.push(
        styles.searchByProduct,
        styles.consignmentAndFormularyFilter
      )
    }
    return filterClasses
  }
  return (
    <SidebarAwareContainer role="filters">
      <div className={cx(...categoryFilterClasses())}>
        <ConsignmentClosetsFilter
          checked={consignmentClosetOnly}
          toggle={toggleConsignmentClosetFilter}
          defaultClosetId={defaultConsignmentClosetId}
          closets={consignmentClosets}
          selectDefaultCloset={selectDefaultConsignmentCloset}
        />
        {canFilterByFormulary && (
          <>
            <h3
              className={cx(
                styles.filterHeading,
                "canopy-mx-4x",
                "canopy-mbs-8x"
              )}
            >
              Formulary
            </h3>
            <Checkbox
              checked={formularyOnly}
              enabled={true}
              toggle={toggleFormularyFilter}
              name="Formulary"
            />
          </>
        )}
        {addDividerPriorToCategoriesSection && (
          <hr className="canopy-mbe-8x canopy-mbs-8x"></hr>
        )}
        {canFilterByYourOrganizationsSuppliers && (
          <div className={styles.yourOrganizationsSuppliersFilter}>
            <h3 className={styles.filterHeading}>Suppliers</h3>
            <div className={styles.yourOrganizationsSuppliersToggleRow}>
              <Switch
                id="yourOrganizationsSuppliers"
                className="yourOrganizationsSuppliers"
                onChange={toggleYourOrganizationsSuppliersFilter}
                height={24}
                width={48}
                dark
                checked={yourOrganizationsSuppliersOnly}
                checkedIcon={
                  <span className={cx(styles.switchLabel, styles.on)}>On</span>
                }
                uncheckedIcon={
                  <span className={cx(styles.switchLabel, styles.off)}>
                    Off
                  </span>
                }
              />
              <label
                htmlFor="yourOrganizationsSuppliers"
                className={styles.yourOrganizationsSuppliersLabel}
              >
                Your Organization's Suppliers Only
              </label>
            </div>
            {manageFulfillmentAgreements && (
              <div className="canopy-mbe-8x">
                <Link href={fulfillmentAgreementsUrl()} target="_blank">
                  <CanopyIcon className="canopy-mie-2x" name="gear" />
                  Manage Suppliers
                </Link>
              </div>
            )}
            <hr className="canopy-mbs-0 canopy-mbe-4x"></hr>
          </div>
        )}
        {currentCatalogBrowseTab === CatalogBrowseTab.Product && (
          <div className="canopy-p-4x">
            <h3 className={styles.filterHeading}>Service Area</h3>
            <Link className={styles.serviceAreas}>
              {selectedServiceAreaState}
            </Link>
            <hr className="canopy-mbe-8x canopy-mbs-8x"></hr>
          </div>
        )}

        <CategorySelector
          options={categoryOptions}
          selectedValue={selectedCategory}
          select={selectCategory}
          usesEnterpriseFeatures={
            dmeOrder.clinicalFacility.usesEnterpriseFeatures
          }
        />
      </div>
    </SidebarAwareContainer>
  )
}

export default ProductFilters
