// @team @destroythefax
import React, { Dispatch, SetStateAction } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  canopyColorBrandPrimary,
  canopyColorTextDanger,
} from "@parachutehealth/canopy-tokens-color"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { Form } from "components/form"
import * as styles from "./index.module.scss"
import { DmeOrder } from "sharedTypes"
import DatePickerInput from "components/input/DatePickerInput"
import { format, isDateAfter, isDateInPast, isDateValid } from "utilities/date"
import { FormikErrors } from "formik"
import classNames from "classnames"

type Props = {
  className: string
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  showSnoozeModal: boolean
  snoozeDmeOrder(params): Promise<void>
  dmeOrder: DmeOrder
}

const SnoozeButton = ({
  className,
  setShowSnoozeModal,
  showSnoozeModal,
  snoozeDmeOrder,
  dmeOrder,
}: Props) => {
  const activatorElementRef = React.useRef<HTMLButtonElement>(null)

  const openSnoozeModal = () => setShowSnoozeModal(true)
  const closeSnoozeModal = () => setShowSnoozeModal(false)

  const handleSubmit = (params, { setSubmitting }) => {
    setSubmitting(true)
    snoozeDmeOrder(params).then((_response) => {
      setSubmitting(false)
    })
  }

  const validate = ({ snoozeDate }) => {
    const errors: FormikErrors<{ snoozeDate: string }> = {}

    if (isDateInPast(snoozeDate)) {
      errors.snoozeDate = "must be after today"
    }

    if (isDateAfter(snoozeDate, dmeOrder.maxSnoozeDate)) {
      errors.snoozeDate = "must be within 12 months of creating order"
    }

    return errors
  }

  const initialValues = {
    snoozeDate: "",
  }

  return (
    <>
      <CanopyButton
        fullWidth
        type="button"
        style={{ color: canopyColorBrandPrimary }}
        className={className}
        variant="tertiary"
        ref={activatorElementRef}
        onClick={openSnoozeModal}
      >
        Snooze Order
      </CanopyButton>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validate}
        validateOnMount={false}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setFieldValue,
          errors,
          validateForm,
          handleReset,
        }) => {
          const hasErrors = Object.keys(errors).length > 0
          const disableSubmit = !isDateValid(values.snoozeDate) || hasErrors
          return (
            <CanopyDialogModal
              className={classNames(
                styles.snoozeModalContainer,
                hasErrors && styles.hasErrors
              )}
              activatorElementRef={activatorElementRef}
              header="Snooze order"
              open={showSnoozeModal}
              onClose={() => {
                handleReset()
                closeSnoozeModal()
              }}
              primaryFooterButton={
                <CanopyButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={disableSubmit}
                  variant="primary"
                  onClick={submitForm}
                >
                  Confirm
                </CanopyButton>
              }
              secondaryFooterButton={
                <CanopyButton
                  variant="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleReset()
                    closeSnoozeModal()
                  }}
                >
                  Cancel
                </CanopyButton>
              }
            >
              <p className="canopy-mbe-8x">
                Order will be indicated as snoozed until date selected.
              </p>
              {errors && (
                <p
                  style={{ color: canopyColorTextDanger }}
                  className="text-center canopy-typography-body-small"
                >
                  {errors.snoozeDate}
                </p>
              )}
              <DatePickerInput
                name="snooze-date"
                onChange={(event) => {
                  const element = event.target as HTMLInputElement
                  setFieldValue("snoozeDate", element.value)
                }}
                minDate={format(dmeOrder.minSnoozeDate, "YYYY-MM-DD")}
                maxDate={format(dmeOrder.maxSnoozeDate, "YYYY-MM-DD")}
                inline
                handleNavigationKey={validateForm}
                hasDateInput={false}
              />
            </CanopyDialogModal>
          )
        }}
      </Form>
    </>
  )
}

export default SnoozeButton
