// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "regenerator-runtime/runtime"
import SentryConfig from "services/sentry"
import { LicenseInfo } from "@mui/x-license-pro"
import { initUserInstruments } from "services/clientsideInstrumentation"
import { enableES5 } from "immer"
import "core-js/stable"
import "vendor"
import "globals"
import $ from "jquery"
import DocumentViewer from "components/document_viewer"
import "utilities/jquery"
import ReactRailsUJS from "react_ujs"

SentryConfig.start(window)

initUserInstruments().catch(() => {
  // reportError(error)
  // We used to report glassbox initialization errors to Sentry, but this error
  // happens so much that it was significantly eating into our Sentry quota.
  // Given that we're considering moving away from Glassbox to Datadog for
  // recorded user sessions, and we're not currently acting on these errors,
  // we've decided to catch the error and do nothing with it.
})

if (window.muiProLicenseKey) {
  LicenseInfo.setLicenseKey(window.muiProLicenseKey)
}

enableES5()

// the components below could be included as separate packs
// in given pages only

$(function () {
  if ($("#document_viewer").length > 0) {
    window.viewer = new DocumentViewer({
      el: $("#document_viewer"),
    })
    window.viewer.initialize()
  }
})

const context = require.context(
  "../applications/",
  true,
  /(ManageSupplierAccess|DashboardTabs|AsyncSupplierLegalEntitySelect|AsyncSalesRepSelect|Login|EpicLogin|TwoFactorAuthentication|PatientPortalMessages|PatientPortalMessages\/.+|CarrierAuthorization|CarrierAuthorizationDashboard|ClinicalDecisionSupport|ClinicalFacilityDashboard|ClinicalFacilityNewOrderButton|AffiliatedDoctorManagement|DoctorSelfVerification|AccessCodeLogin|CsrInboxStateAiDetails|CsrInboxStateAssigneeEditor|DocumentDashboard|FulfillmentEditor|InternalStatusEditor|NewOrderForm|PatientSelect|PatientProfile|SupplierDashboard|SupplierInsuranceSettings|SupplierOrganizationCarrierAuthorizationDashboard|SupplierOrganizationDashboard|SupplierOrganizationSettings|DoctorApproval|DoctorDashboard|Workflow|Pinger|DatePickerInput|FacilitySignUp\/Invitation|FacilitySignUp\/AccountCompletion|FacilitySignUp\/SupplierInfoCenter\/AccountCompletion|FacilitySignUp\/SupplierInfoCenter\/AccountCreation|FacilitySelection|SupplierOrganizationNewOrderButton|ResetPassword|ForgotPassword|NewInvitationPassword|DocumentationOrders|SigningSignatureRequestApprovalPage|SupplierFacilitySignUp|SupplierOrganizationUserForm|EmrDmeOrderCreationPoller|ClinicalOrganizationUserForm|ClinicalOrganizationFinancials|EmrEmailCapture|EmrPatientLoading|CsrInboxState|ManageSuppliers|SupplierAdminServiceAreaForm|SigningSignatureRequestsAccess|SoftLandingPage|ReviewerExpiredVerificationOptInLinkForm|SignatureRequestPatientCards|ReviewerManageNotificationsForm|ReassignSalesReps|ReviewerInvitationWelcomeForm|DmeOrderPatientCards|ReviewerUserPreferences|CanopyIcons|OrderTrackerSettings|ReviewerSignUp|ReviewerSignUpPromo|DoctorReviewerDashboard|ZipCodeConflictModal|SalesRepTerritoryForm|ManageUsers\/.+|FacilitySupportPage|SalesRepTerritoriesTable|CustomerSupportLinkButton|PreferencesLinkButton|NotificationDropdown|Insurance\/Payor\/MemberServicesDashboard|IncomingOrders|Insurance\/Payor\/UsersListPage|Insurance\/Payor\/UserPreferences)\/index$/
)
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(context)

ReactRailsUJS.getConstructor = (className: string) => {
  return context(`./${className}/index`).default
}
